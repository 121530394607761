import {
  createStackNavigator,
  StackScreenProps,
} from '@react-navigation/stack';
import { Formik } from 'formik';
import React from 'react';
import { useToast } from 'react-native-toast-notifications';
import { InferType } from 'yup';

import { ContactGroupForm } from '@components/Contacts/ContactGroupForm.web';
import { TeamSelect } from '@components/FormModals/TeamSelect';
import { useWebDrawer } from '@components/Web/Drawer/WebDrawerContext';
import {
  useCreateGroupMutation,
  useUpdateGroupMutation,
} from '@graphql/generated';
import { stackScreenOptions } from '@navigation/payments/payments-stack';
import createContactGroupSchema from '@schemas/createContactGroupSchema';

export type FormValues = InferType<typeof createContactGroupSchema> & {
  id?: string;
};

export type ContactFormStackParamList = {
  'contact-form': undefined;
  'team-select': undefined;
};

export type ContactFormStackScreenProps<
  T extends keyof ContactFormStackParamList
> = StackScreenProps<ContactFormStackParamList, T>;

const Stack = createStackNavigator<ContactFormStackParamList>();

const ContactFormStack: React.FC<{
  screenName?: string;
  currentUserTeamRole?: string;
  initialValues: FormValues;
}> = ({ screenName, currentUserTeamRole, initialValues }) => {
  const toast = useToast();
  const { setIsContactWebPanelOpen, setIdContactDetailOpen, setEditContactId } =
    useWebDrawer();

  const [createGroupMutation] = useCreateGroupMutation({
    refetchQueries: ['listGroups'],
    onCompleted: (data) => {
      if (data?.createGroup) {
        setIdContactDetailOpen(data.createGroup.id);
        setIsContactWebPanelOpen(true);
        toast.show('Contact Group Created Successfully', { duration: 3000 });
      }
    },
  });

  const [updateGroupMutation] = useUpdateGroupMutation({
    refetchQueries: ['listGroups'],
    onCompleted: () => {
      setEditContactId('');
      setIsContactWebPanelOpen(false);
      toast.show('Contact Group Updated Successfully', { duration: 3000 });
    },
  });

  const submit = (values: FormValues) => {
    const variables = {
      attributes: {
        name: values.name,
        accountId: values.teamId,
        userIds: values.users.map((user) => user.id),
      },
    };

    if (screenName === 'CreateGroup') {
      createGroupMutation({ variables });
    } else {
      values.id &&
        updateGroupMutation({ variables: { id: values.id, ...variables } });
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(values) => submit(values)}
      validationSchema={createContactGroupSchema}>
      {() => {
        return (
          <Stack.Navigator
            initialRouteName='contact-form'
            screenOptions={{
              ...stackScreenOptions,
              presentation: 'transparentModal',
            }}>
            <Stack.Screen name='contact-form'>
              {() => (
                <ContactGroupForm
                  screenName={screenName}
                  currentUserTeamRole={currentUserTeamRole}
                />
              )}
            </Stack.Screen>

            <Stack.Screen
              name='team-select'
              component={TeamSelect}
              options={{ title: 'Team' }}
            />
          </Stack.Navigator>
        );
      }}
    </Formik>
  );
};

export default ContactFormStack;
