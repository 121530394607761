import { useNavigation } from '@react-navigation/native';
import { Field, Formik, FormikProps } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, FlatList, TouchableOpacity } from 'react-native';
import { InferType } from 'yup';

import Avatar from '@components/Avatar/Avatar';
import EmptyStateNoTeams from '@components/EmptyState/EmptyStateNoTeams';
import { SkillSelect } from '@components/FormModals/SkillSelect.web';
import {
  LocationSearch,
  Location,
} from '@components/LocationSearch/LocationSearch.web';
import { WebModal } from '@components/Modals/WebModal.web';
import { Box, Text } from '@components/Restyle';
import Icon from '@components/shared/Icon/Icon';
import { Tag } from '@components/shared/Tags/Tag';
import TextField from '@components/shared/TextField/TextField';
import { TeamListItem } from '@components/Team/TeamListItem';
import { PopupTimeZoneButtonWeb } from '@components/Web/PopupTimeZoneButton.web';
import { useUpdateMeMutation } from '@graphql/generated';
import useFileProcessor from '@hooks/useFileProcessor';
import { useListTeamsFromQuery } from '@hooks/useListTeamsFromQuery';
import useMe from '@hooks/useMe';
import { useSkills } from '@hooks/useSkills';
import saveProfileSchema from '@schemas/saveProfileSchema';
import theme from '@themes/theme';
import { useFilePicker } from '@utils/filePicker';
import { guessTimeZone, humanTimeZoneName } from '@utils/formatters/date';

export type SaveProfileFormValues = InferType<typeof saveProfileSchema>;

const EditProfile: React.FC = () => {
  const navigation = useNavigation();
  const { t } = useTranslation();
  const { launchImageSelection } = useFilePicker();
  const { me } = useMe();
  const skills = useSkills();
  const [overrideSaveAlert, setOverrideSaveAlert] = useState(false);
  const [skillsModalOpen, setSkillsModalOpen] = useState<boolean>(false);
  const [locationModalOpen, setLocationModalOpen] = useState<boolean>(false);

  const { processFiles } = useFileProcessor();

  const [updateMe] = useUpdateMeMutation();

  const { teams } = useListTeamsFromQuery();

  const onSubmit = (values: SaveProfileFormValues) => {
    const {
      name,
      phoneNumber,
      skillIds: skillIdsRaw,
      location,
      timeZone,
    } = values;
    const { address, latitude, longitude } = location || {
      address: undefined,
      latitude: undefined,
      longitude: undefined,
    };
    const skillIds = skillIdsRaw.filter((s) => s) as string[];
    if (values.avatar && me) {
      const { clientId, contentType, isImage, size, duration, cdnBaseUrl } =
        values.avatar;
      // avatar is a local file at this point
      const doc: Document = {
        __typename: 'Document',
        id: 'avatar',
        owner: me,
        name,
        clientId,
        contentType,
        isImage,
        createdAt: new Date().toISOString(),
        size,
        file: {
          __typename: 'File',
          id: new Date().toISOString(),
          cdnBaseUrl,
          url: '',
          path: '',
          ...values.avatar,
        },
        isAudio: false,
        duration,
      };
      const documents = [doc];

      processFiles(documents)
        .then((response) => {
          const { blobId } = response[0];
          updateMe({
            variables: {
              attributes: {
                name,
                phoneNumber,
                timeZone,
                skillIds,
                avatarBlobId: blobId,
                address,
                latitude,
                longitude,
              },
            },
            onError: (e) => {
              alert(e);
            },
          });
        })
        .catch((err) => {
          alert(err);
        });
    } else {
      updateMe({
        variables: {
          attributes: {
            name,
            phoneNumber,
            timeZone,
            skillIds,
            avatarBlobId: '',
            address,
            latitude,
            longitude,
          },
        },
        onError: (e) => {
          alert(e);
        },
      });
    }
  };

  const mySkills =
    me?.skills?.map((item) => {
      return item.id;
    }) || [];

  const initialValues: SaveProfileFormValues = {
    avatar: null,
    name: me?.name || '',
    email: me?.email || '',
    phoneNumber: me?.phoneNumber || '',
    timeZone: me?.timeZone || guessTimeZone(),
    location: me?.address
      ? {
          latitude: me?.latitude || 0,
          longitude: me?.longitude || 0,
          address: me.address,
        }
      : null,
    skillIds:
      me?.skills?.map((item) => {
        return item.id;
      }) || [],
  };

  const ProfileComponent = (props: FormikProps<SaveProfileFormValues>) => {
    const { values, touched, setTouched, setFieldValue } = props;
    const { avatar, name } = me || {};
    const { skillIds, avatar: newAvatar, location } = values;

    const openGallery = async () => {
      await launchImageSelection().then((r) => {
        r && setFieldValue('avatar', r[0]);

        setTouched({ ...touched, avatar: true });
      });
    };

    const saveAlert = () => {
      Alert.alert(
        t('models:saveProject.title'),
        t('models:saveProject.message'),
        [
          {
            text: t('shared:cancel'),
          },
          {
            text: t('shared:leavePage'),

            onPress: () => {
              setOverrideSaveAlert(true);
              if (touched.avatar) {
                values.avatar = null;
              }
              if (touched.name) {
                values.name = me?.name ?? '';
              }
              if (touched.phoneNumber) {
                values.phoneNumber = me?.phoneNumber ?? '';
              }
              if (touched.location) {
                values.location = '';
              }
              if (touched.skillIds) {
                values.skillIds =
                  me?.skills?.map((item) => {
                    return item.id;
                  }) || [];
              }
              // debounce, else alert will show twice
              setTimeout(() => {
                setTouched({});
                navigation.goBack();
              }, 10);
            },
          },
        ]
      );
    };
    const setTimeZone = (value: string) => {
      if (touched.timeZone) {
        values.timeZone = me?.timeZone ?? '';
      }
      setTouched({ ...touched, timeZone: true });

      setFieldValue('timeZone', value);
    };

    useEffect(
      () =>
        navigation.addListener('beforeRemove', (e) => {
          if (overrideSaveAlert) {
            return;
          }
          if (Object.entries(touched).length > 0) {
            e.preventDefault();
            saveAlert();
          } else {
            return;
          }
        }),
      [navigation, touched, overrideSaveAlert]
    );

    useEffect(() => {
      values.avatar !== null && onSubmit(values);
    }, [values.avatar]);

    useEffect(() => {
      values.name !== me?.name && onSubmit(values);
    }, [values.name]);

    useEffect(() => {
      values.location?.address !== me?.address && onSubmit(values);
    }, [values.location]);

    useEffect(() => {
      values.timeZone !== me?.timeZone && onSubmit(values);
    }, [values.timeZone]);

    useEffect(() => {
      const compare = (a: string | undefined, b: string | undefined) =>
        (a ?? '').localeCompare(b ?? '');
      values.skillIds.toSorted(compare).toString() !==
        mySkills.toSorted(compare).toString() && onSubmit(values);
    }, [values.skillIds]);

    const offsetPopButton: Partial<any> = {
      position: 'right',
      offset: [0, 6],
    };

    return (
      <Box>
        <Box width={602}>
          <Box marginBottom='m' marginTop='l' alignItems='center'>
            <Avatar
              avatar={newAvatar ? newAvatar : avatar}
              width={94}
              height={94}
              label={name}
            />

            <TouchableOpacity
              onPress={openGallery}
              accessibilityLabel={t('models:saveProfile.editPhoto')}>
              <Text marginTop='m' color='greenSecondary' variant='webSmall'>
                {t('models:saveProfile.editPhoto')}
              </Text>
            </TouchableOpacity>
          </Box>

          <Field
            component={TextField}
            label='Name'
            name='name'
            accessibilityLabel={t('models:saveProfile.name')}
            placeholder={t('models:saveProfile.name')}
            textInputProps={{ editable: true }}
          />

          <Text color='textPrimary' variant='webSmall'>
            {t('models:saveProfile.location')}
          </Text>
          <TouchableOpacity
            accessibilityLabel={t('models:saveProfile.location')}
            onPress={() => setLocationModalOpen(true)}>
            <Box
              flexDirection='row'
              borderColor='grey02'
              borderWidth={1}
              height={48}
              alignItems='center'
              borderRadius='xs'
              paddingHorizontal='m'
              mt='xs'
              mb='m'>
              {location && (
                <Text
                  variant='bodySecondary'
                  color='black'
                  numberOfLines={1}
                  mr='m'>
                  {location.address}
                </Text>
              )}
              <Box flex={1}></Box>
              <Icon name='MapPin' variant='l' color='black' />
            </Box>
          </TouchableOpacity>

          <Field
            component={TextField}
            label='Phone Number'
            name='phoneNumber'
            accessibilityLabel={t('models:saveProfile.phoneNumber')}
            placeholder={t('models:saveProfile.phoneNumber')}
            customBorderColor={theme.colors.grey02}
            customBackgroundColor={theme.colors.grey01}
            textInputProps={{
              editable: false,
            }}
          />

          <Field
            component={TextField}
            label='Email'
            name='email'
            accessibilityLabel={t('models:saveProfile.email')}
            placeholder={t('models:saveProfile.email')}
            customBorderColor={theme.colors.grey02}
            customBackgroundColor={theme.colors.grey01}
            textInputProps={{
              editable: false,
            }}
          />

          <Text color='textPrimary' variant='webSmall' marginBottom='xs'>
            {t('models:saveProfile.timeZone')}
          </Text>
          <PopupTimeZoneButtonWeb
            title={humanTimeZoneName(values.timeZone ?? '') ?? ''}
            setTimeZone={setTimeZone}
            boxProps={offsetPopButton}
            width={602}
            maxHeight={150}
          />

          <Box marginTop='m' flexDirection='row'>
            <Text marginRight='xs' variant='webSmall' color='textPrimary'>
              {t('models:saveProfile.skills')}
            </Text>
            <Icon name='AlertCircle' variant='s' color='textPrimary' />
          </Box>
          {/* eslint-disable-next-line no-magic-numbers */}
          {skillIds && skillIds.length > 0 && (
            <Box mt='xs' flexDirection='row' flexWrap='wrap' mb='s'>
              <Tag
                label='+ Add Skills'
                labelVariant='labelSmall'
                variant='reversed'
                marginRight='xxs'
                padding='xxs'
                borderRadius='xxs'
                onPress={() => setSkillsModalOpen(true)}
              />

              {skillIds.map((item) => {
                const skill = skills.find((i) => i.id === item);

                if (!skill) return null;

                const { id: skillId, name: skillName } = skill;

                return (
                  <Tag
                    key={skillId}
                    label={skillName}
                    labelVariant='labelSmall'
                    variant='status'
                    marginRight='xxs'
                    padding='xxs'
                    borderRadius='xxs'
                    onDismiss={() => {
                      const skillIds = values.skillIds.filter(
                        (i) => i !== skillId
                      );
                      setTouched({ ...touched, skillIds: true });
                      setFieldValue('skillIds', [...skillIds]);
                    }}
                    marginBottom='xs'
                  />
                );
              })}
            </Box>
          )}
          {/* eslint-disable-next-line no-magic-numbers */}
          {!!skillIds && skillIds.length < 1 && (
            <Box mt='xs' width={92.22} flexDirection='row'>
              <Tag
                label='+ Add Skills'
                labelVariant='labelSmall'
                variant='reversed'
                marginRight='xxs'
                padding='xxs'
                borderRadius='xxs'
                onPress={() => setSkillsModalOpen(true)}
              />
              <Box flex={1}></Box>
            </Box>
          )}

          <Box style={{ marginTop: 32 }}>
            <Box>
              <Text variant='heading2' color='black'>
                {t('models:projects.create.teams.title')}
              </Text>
            </Box>
            <Box flex={1}>
              {!teams ? (
                <EmptyStateNoTeams />
              ) : (
                <FlatList
                  data={teams}
                  keyExtractor={(item) => item.id}
                  renderItem={({ item }) => (
                    <Box>
                      <TeamListItem
                        key={item.id}
                        team={item}
                        onSelect={() => {
                          navigation.navigate('team-details', {
                            teamId: item.id,
                          });
                        }}
                      />
                    </Box>
                  )}
                  ListHeaderComponent={() => <Box marginTop='m' />}
                  ItemSeparatorComponent={() => <Box marginBottom='m' />}
                />
              )}
            </Box>
          </Box>
        </Box>

        <WebModal
          onClose={() => setLocationModalOpen(false)}
          visible={locationModalOpen}
          width={504}
          title='Location'
          height={512}>
          <Box flex={1} margin='m'>
            <LocationSearch
              isShowCancel={false}
              onClose={() => setLocationModalOpen(false)}
              onSelect={(location: Location) => {
                setFieldValue('location', location);
                setTouched({ ...touched, location: true });
              }}
            />
          </Box>
        </WebModal>
        {skillsModalOpen && (
          <SkillSelect onClose={() => setSkillsModalOpen(false)} />
        )}
      </Box>
    );
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => onSubmit(values)}
      validationSchema={saveProfileSchema}>
      {(props) => {
        return ProfileComponent(props);
      }}
    </Formik>
  );
};

export default EditProfile;
