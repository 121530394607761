import { CommonActions, useNavigation } from '@react-navigation/native';
import {
  StackScreenProps,
  createStackNavigator,
} from '@react-navigation/stack';
import React from 'react';
import { Platform } from 'react-native';

import SecondaryHeader from '@components/Headers/SecondaryHeader';
import { Product, Team } from '@graphql/generated';
import CreateSubscriptionStack from '@navigation/payments/create-subscription-stack';
import { PlanSelect } from '@screens/Payments/PlanSelect';
import { SubscriptionCreatedModal } from '@screens/Payments/SubscriptionCreateModal';
import UpgradePlus from '@screens/Payments/UpgradePlus';
import { actionsModal } from '@utils/actionsModal';
export type PaymentsStackParamsList = {
  'upgrade-plus': undefined;
  'pick-plans': undefined;
  'create-subscriptionPanel': {
    planId: string;
    teamId: string;
    isPersonal: boolean;
    isPlanPlus: boolean;
    screenName: string;
    teamvalues: any;
  };
  'create-subscription': {
    planId: string;
    teamId: string;
    isPersonal: boolean;
    isPlanPlus: boolean;
    screenName: string;
  };
  'subscription-created': { teamId: Team['id']; productId: Product['id'] };
};
export type PaymentsStackScreenProps<T extends keyof PaymentsStackParamsList> =
  StackScreenProps<PaymentsStackParamsList, T>;

const Stack = createStackNavigator<PaymentsStackParamsList>();

const PaymentsStack: React.FC<{
  teamId?: string | undefined;
  isPersonal?: boolean;
  isPlanPlus?: boolean;
  screenName?: keyof PaymentsStackParamsList | undefined;
  externalNavigate?: any;
  teamVaules?: any;
}> = ({
  teamId = '',
  isPersonal = false,
  isPlanPlus = false,
  screenName = Platform.OS === 'web' ? 'pick-plans' : 'upgrade-plus',
  externalNavigate,
  teamVaules,
}) => {
  const navigation = useNavigation();
  return (
    <Stack.Navigator initialRouteName={screenName}>
      <Stack.Screen
        name='upgrade-plus'
        component={UpgradePlus}
        options={{
          header: () => <SecondaryHeader title='Plans' searchable={false} />,
        }}
      />
      <Stack.Screen
        name='pick-plans'
        component={PlanSelect}
        options={{
          headerShown: false,
        }}
      />

      <Stack.Screen
        name='create-subscriptionPanel'
        children={() => {
          return (
            <CreateSubscriptionStack externalNavigate={externalNavigate} />
          );
        }}
        // component={() => {
        //   return (
        //     <CreateSubscriptionStack externalNavigate={externalNavigate} />
        //   );
        // }}
        initialParams={{
          teamId,
          teamvalues: teamVaules,
          isPersonal: isPersonal,
          isPlanPlus: isPlanPlus,
          screenName: 'create-subscriptionPanel',
        }}
        options={{
          headerShown: false,
          header: () => <SecondaryHeader title='Upgrade' searchable={false} />,
        }}
      />
      <Stack.Screen
        name='create-subscription'
        // component={() => {
        //   return (
        //     <CreateSubscriptionStack externalNavigate={externalNavigate} />
        //   );
        // }}
        children={() => {
          return (
            <CreateSubscriptionStack externalNavigate={externalNavigate} />
          );
        }}
        options={{
          headerShown: false,
          header: () => <SecondaryHeader title='Upgrade' searchable={false} />,
        }}
      />
      <Stack.Group screenOptions={stackScreenOptions}>
        <Stack.Screen
          name='subscription-created'
          options={{ title: 'Congratulations!' }}>
          {() => (
            <SubscriptionCreatedModal
              onClose={() => {
                // This works around a react navigation issue that was difficult to overcome
                // where the modal kept persisting no matter how navigation was reset
                // if (!window)
                //   return
                // window.location.href = '/'
                //
                navigation.dispatch(
                  CommonActions.reset({
                    index: 0,
                    routes: [{ name: 'home' }],
                  })
                );
                if (!window) return;

                actionsModal();
              }}
              buttonLabel='Confirm'
              title='Congratulations!'
            />
          )}
        </Stack.Screen>
      </Stack.Group>
    </Stack.Navigator>
  );
};

export default PaymentsStack;

export const stackScreenOptions = {
  headerShown: false,
  title: 'TaskTag',
  presentation: 'transparentModal',
  cardShadowEnabled: Platform.OS === 'web',
  cardStyleInterpolator: ({ current: { progress } }) => ({
    containerStyle: {
      opacity: progress.interpolate({
        inputRange: [0, 1],
        outputRange: [0, 1],
        extrapolate: 'clamp',
      }),
    },
    overlayStyle: {
      opacity: progress.interpolate({
        inputRange: [0, 1],
        outputRange: [0, 0.6],
        extrapolate: 'clamp',
      }),
    },
  }),
};
