import { array, object, string } from 'yup';

import { userSchema } from '@schemas/userSchema';

const createContactGroupSchema = object({
  name: string()
    .matches(
      /^$|^[0-9a-zA-Z][^]*$/,
      'Name cannot start with special characters/emoji.'
    )
    .required('Name is a required field'),
  teamId: string().required('Team is required'),
  users: array().of(userSchema).ensure(),
});

export default createContactGroupSchema;
