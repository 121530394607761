import React from 'react';

import { ActivityItemDocument } from '@components/ActivityList/ActivityItemDocument';

import ChatMessage from '../../components/Chat/ChatMessage';
import TagsCollection from '../../components/Chat/TagsCollection';
import {
  Audit,
  Attachment,
  Task,
  TaskUser,
  EntityUser,
  Message,
  Document,
} from '../../graphql/generated';

export const getTaskActivityMessage = (
  event: TaskActivityType,
  role?: string,
  messageBody?: string
) => {
  switch (event) {
    case TaskActivityType.TaskCompleted:
      return 'Completed this task';
    case TaskActivityType.TaskAssigned:
      return 'Added as Task ' + role;
    case TaskActivityType.TaskCreated:
      return 'Created this task';
    case TaskActivityType.TaskCompletedRestore:
      return 'Restored this task';
    case TaskActivityType.TaskUnassigned:
      return 'Unassigned from this task';
    case TaskActivityType.TaskUnassignedSelf:
      return 'Left this task';
    case TaskActivityType.MessageSent:
      return messageBody;
    case TaskActivityType.TaskDocument:
      return 'Added file to this task';
    default:
      return '';
  }
};

export enum TaskActivityType {
  TaskDocument = 'TaskDocument',
  MessageSent = 'MessageSent',
  TaskCreated = 'TaskCreated',
  TaskUpdated = 'TaskUpdated',
  TaskAssigned = 'TaskAssigned',
  TaskUnassigned = 'TaskUnassigned',
  TaskUnassignedSelf = 'TaskUnassignedSelf',
  TaskCompleted = 'TaskCompleted',
  TaskCompletedRestore = 'TaskCompletedRestore',
  Default = '',
}

const allowedActivities = [
  TaskActivityType.TaskCompleted,
  TaskActivityType.TaskAssigned,
  TaskActivityType.TaskCreated,
  TaskActivityType.TaskCompletedRestore,
  TaskActivityType.TaskUnassigned,
  TaskActivityType.TaskUnassignedSelf,
  TaskActivityType.MessageSent,
];

export const filteredTaskAudits = (audits: Audit[]) => {
  return audits.filter((audit) =>
    allowedActivities.includes(audit.event as TaskActivityType)
  );
};

type TaskActivityItemProps = {
  audit: Audit;
};

const TaskActivityItem: React.FC<TaskActivityItemProps> = ({ audit }) => {
  const { attachment, user } = audit;
  const isTaskAssigned = audit.event === 'TaskAssigned';
  const isTaskUnassigned = audit.event === 'TaskUnassigned';
  const isTaskUnassignedSelf = audit.event === 'TaskUnassignedSelf';

  const isMessageType = (item: Attachment): item is Message =>
    item.__typename === 'Message';

  const isTaskType = (item: Attachment): item is Task =>
    item.__typename === 'Task';

  const isTaskUserType = (item: Attachment): item is TaskUser =>
    item.__typename === 'TaskUser';

  const isEntityType = (item: Attachment): item is EntityUser =>
    item.__typename === 'EntityUser';

  const isDocumentType = (item: Attachment): item is Document =>
    item.__typename === 'Document';

  if (!attachment) {
    return null;
  }

  if (isMessageType(attachment)) {
    return <ChatMessage message={attachment} isActivityItem />;
  }

  if (isDocumentType(attachment)) {
    return (
      <ActivityItemDocument attachment={attachment}></ActivityItemDocument>
    );
  }

  if (isTaskType(attachment)) {
    return (
      <TagsCollection
        showAuthor={false}
        tagsCollection={{
          tasks: [attachment],
          project: attachment.project,
          author: user,
        }}
      />
    );
  }

  if (isTaskUserType(attachment)) {
    return (
      <TagsCollection
        showAuthor={false}
        tagsCollection={{
          tasks: [attachment.task],
          project: attachment.task.project,
          author: user,
        }}
      />
    );
  }

  if (
    isEntityType(attachment) &&
    (isTaskAssigned || isTaskUnassigned || isTaskUnassignedSelf)
  ) {
    return (
      <TagsCollection
        showAuthor={false}
        tagsCollection={{
          tasks: attachment.entityTask ? [attachment.entityTask] : [],
          project: attachment.entityProject || attachment.entityTask.project,
          author: user,
        }}
      />
    );
  }
  return null;
};

export default TaskActivityItem;
