import React from 'react';

import { ActiveChatProvider } from '@hooks/useActiveChat';
import { ChatInputProvider } from '@hooks/useChatInput';
import { ChatProcessingProvider } from '@hooks/useChatProcessing';
import { ContactsProvider } from '@hooks/useContacts';
import { DownloadFilesProvider } from '@hooks/useDownloadFiles';
import { EmojiProvider } from '@hooks/useEmoji';
import { FeatureFlagProvider } from '@hooks/useFeatureFlag';
import { GlobalSearchProvider } from '@hooks/useGlobalSearch';
import { GlobalSearchProviderProject } from '@hooks/useGlobalSearchProjects';
import { MeProvider } from '@hooks/useMe';
import { NotificationProvider } from '@hooks/useNotification';
import { PhasesProvider } from '@hooks/usePhases';
import { PreviewDocumentsProvider } from '@hooks/usePreviewDocuments';
import { ProjectsFilterProvider } from '@hooks/useProjectsFilter';
import { RevenueCatProvider } from '@hooks/useRevenueCat';
import { SearchProvider } from '@hooks/useSearch';
import { SelectedMembersProvider } from '@hooks/useSelectedMembers';
import { TabFocusProvider } from '@hooks/useTabFocus';
import { TagInputProvider } from '@hooks/useTagInput';
import { TaskProvider } from '@hooks/useTask';
import { TasksFilterProvider } from '@hooks/useTasksFilter';
import { UploadFilesProvider } from '@hooks/useUploadFiles';
import { UsersProvider } from '@hooks/useUsers';

export const CoreProviders = ({ children }: { children: React.ReactNode }) => (
  <MeProvider>
    <FeatureFlagProvider>
      <TabFocusProvider>
        <GlobalSearchProvider>
          <GlobalSearchProviderProject>
            <TagInputProvider>
              <PreviewDocumentsProvider>
                <ActiveChatProvider>
                  <NotificationProvider>
                    <SearchProvider>
                      <ChatInputProvider>
                        <ChatProcessingProvider>
                          <UsersProvider>
                            <ContactsProvider>
                              <DownloadFilesProvider>
                                <UploadFilesProvider>
                                  <ProjectsFilterProvider>
                                    <PhasesProvider>
                                      <TasksFilterProvider>
                                        <TaskProvider>
                                          <SelectedMembersProvider>
                                            <EmojiProvider>
                                              <RevenueCatProvider>
                                                {children}
                                              </RevenueCatProvider>
                                            </EmojiProvider>
                                          </SelectedMembersProvider>
                                        </TaskProvider>
                                      </TasksFilterProvider>
                                    </PhasesProvider>
                                  </ProjectsFilterProvider>
                                </UploadFilesProvider>
                              </DownloadFilesProvider>
                            </ContactsProvider>
                          </UsersProvider>
                        </ChatProcessingProvider>
                      </ChatInputProvider>
                    </SearchProvider>
                  </NotificationProvider>
                </ActiveChatProvider>
              </PreviewDocumentsProvider>
            </TagInputProvider>
          </GlobalSearchProviderProject>
        </GlobalSearchProvider>
      </TabFocusProvider>
    </FeatureFlagProvider>
  </MeProvider>
);
