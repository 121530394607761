import { useRoute } from '@react-navigation/native';
import dayjs from 'dayjs';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import DatePicker from '@components/DatePicker/DatePicker';
import {
  FormValues,
  TaskFormStackScreenProps,
} from '@components/Tasks/TaskFormStack';
import { TaskStatus } from '@graphql/generated';
import { beginningOfToday, localDateFromUTCDate } from '@utils/formatters/date';

type DateSelectPickerProps = {
  onClose?: () => void;
  formField?: 'startDate' | 'dueDate';
  isBottomSheet?: boolean;
  submitWhenSelected?: boolean;
};

export const DateSelectPicker: React.FC<DateSelectPickerProps> = ({
  onClose,
  formField,
  isBottomSheet,
  submitWhenSelected = false,
}) => {
  const route = useRoute<TaskFormStackScreenProps<'date-select'>['route']>();
  const { params } = route;
  const { field: paramsField } = params || { paramsField: undefined };

  const field = paramsField || formField;

  if (!field) return null;

  const { t } = useTranslation('format');

  const {
    setFieldValue,
    setTouched,
    touched,
    getFieldProps,
    values,
    submitForm,
  } = useFormikContext<FormValues>();
  const { startDate, dueDate } = values;
  const { name: fieldName, value } = getFieldProps(field);

  return (
    <DatePicker
      onClose={onClose}
      isBottomSheet={isBottomSheet}
      minDate={
        fieldName === 'dueDate' && startDate
          ? localDateFromUTCDate(startDate)
          : undefined
      }
      maxDate={
        fieldName === 'startDate' && dueDate
          ? localDateFromUTCDate(dueDate)
          : undefined
      }
      title={
        fieldName === 'startDate'
          ? t('models:tasks.create.dates.startDate')
          : t('models:tasks.create.dates.dueDate')
      }
      selectedDate={value != null ? localDateFromUTCDate(value) : null}
      onSelectDate={(date: Date | undefined) => {
        setTouched({ ...touched, [fieldName]: true });
        setFieldValue(fieldName, date ? date : undefined);

        const today = beginningOfToday();

        if (date) {
          const isToday = dayjs().isSame(date, 'day');
          const isPast = date < today;
          const isFuture = date > today;

          if (fieldName === 'startDate') {
            if (isFuture) {
              setFieldValue('status', TaskStatus.NotStarted);
            } else if (isPast || isToday) {
              setFieldValue('status', TaskStatus.Ongoing);
            }
            if (dueDate && dueDate < today) {
              setFieldValue('status', TaskStatus.Overdue);
            }
          }

          if (fieldName === 'dueDate') {
            if (isPast) {
              setFieldValue('status', TaskStatus.Overdue);
            } else if (isFuture || isToday) {
              if (
                startDate &&
                (startDate < today || dayjs().isSame(startDate, 'day'))
              ) {
                setFieldValue('status', TaskStatus.Ongoing);
              } else {
                setFieldValue('status', TaskStatus.NotStarted);
              }
            }
          }
        }
        if (submitWhenSelected) submitForm();
        else onClose?.();
      }}
    />
  );
};
