/* eslint-disable no-nested-ternary */
import { NavigationContainer } from '@react-navigation/native';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { WebModal } from '@components/Modals/WebModal.web';
import CreateProject from '@components/Projects/CreateProject';
import { Box, Text } from '@components/Restyle';
import Icon from '@components/shared/Icon/Icon';
import { TaskWebPanel } from '@components/Tasks/TaskWebPanel';
import {
  ChatEmptyState,
  EmptyStateType,
} from '@components/Web/Chats/ChatEmptyState.web';
import { useWebDrawer } from '@components/Web/Drawer/WebDrawerContext';
import { Project, Task, User } from '@graphql/generated';
import CreateSubscriptionStack from '@navigation/payments/create-subscription-stack';
import { useAppNavigation } from '@navigation/useAppNavigation';
import CreateContactStack from '@screens/Contacts/CreateContactStack.web';
import theme from '@themes/theme';

export const MainPanel = ({ children }: { children: React.ReactNode }) => {
  const {
    chatExpanded,
    isBottomBannerShow,
    setIsBottomBannerShow,
    isCreateProjectOpen,
    setIsCreateProjectOpen,
    editProjectId,
    setEditProjectId,
    isTaskWebPanelOpen,
    setIsTaskWebPanelOpen,
    idTaskDetailOpen,
    setIdTaskDetailOpen,
    isTaskWebPanelMaximize,
    mainPanelWidth,
    isUpgradeTeamPlanOpen,
    idTeamOpen,
    teamValues,
    isTeamPersonal,
    idContactDetailOpen,
    isContactWebPanelOpen,
  } = useWebDrawer();
  const [isModalShow, setIsModalShow] = useState(false);
  const { t } = useTranslation();
  const navigation = useAppNavigation();
  const { t: s } = useTranslation('shared');

  const panelContent = (isTeam: boolean) => {
    const width = !isTeam && isTaskWebPanelMaximize ? mainPanelWidth : 580;
    const initialParams = isTeam
      ? {
          teamId: idTeamOpen,
          teamValues: teamValues,
          isPersonal: isTeamPersonal,
          isPlanPlus: false,
          screenName: 'create-subscriptionPanel',
        }
      : {};
    return (
      <NavigationContainer
        documentTitle={{ enabled: true, formatter: () => s('appName') }}
        independent={true}>
        <Box
          accessibilityLabel='Task modal'
          style={{
            borderColor: theme.colors.black,
            borderStyle: 'solid',
            backgroundColor: theme.colors.white,
            elevation: 5,
            shadowColor: theme.colors.buttonBackground,
            shadowOpacity: 0.1,
            shadowRadius: 40,
          }}
          position='absolute'
          right={0}
          bottom={0}
          top={0}
          width={width}
          p='l'>
          {isTeam && (
            <CreateSubscriptionStack
              externalNavigate={navigation}
              params={initialParams}
            />
          )}
          {!isTeam && (
            <TaskWebPanel
              externalNavigate={navigation}
              idtask={idTaskDetailOpen}
              onNavigation={(task: Task) => {
                setIdTaskDetailOpen(task.id);
                setIsTaskWebPanelOpen(true);
              }}
              onPressCreator={(task: Task) => {
                isTaskWebPanelOpen && setIsTaskWebPanelOpen(false);
                navigation.navigateToContact(task.creator);
              }}
              onPressMember={(item: User) => {
                isTaskWebPanelOpen && setIsTaskWebPanelOpen(false);
                navigation.navigateToContact(item);
              }}
              onPressProject={(project: Project) => {
                isTaskWebPanelOpen && setIsTaskWebPanelOpen(false);
                navigation.navigateToProject(project);
              }}
            />
          )}
        </Box>
      </NavigationContainer>
    );
  };

  return (
    <Box flex={chatExpanded ? 0 : 1} flexDirection='column'>
      {children}
      {!chatExpanded &&
        isUpgradeTeamPlanOpen &&
        window.location.pathname.startsWith('/my-account') &&
        panelContent(true)}
      {!chatExpanded && isTaskWebPanelOpen && panelContent(false)}

      {!chatExpanded && isContactWebPanelOpen && (
        <NavigationContainer independent={true}>
          <Box
            style={{
              borderColor: theme.colors.black,
            }}
            position='absolute'
            right={0}
            bottom={0}
            top={0}
            width={isTaskWebPanelMaximize ? mainPanelWidth : 580}
            borderColor='grey02'>
            {isContactWebPanelOpen && (
              <CreateContactStack contactGroupId={idContactDetailOpen} />
            )}
          </Box>
        </NavigationContainer>
      )}

      {isCreateProjectOpen && (
        <NavigationContainer independent={true}>
          <Box
            position='absolute'
            right={0}
            bottom={0}
            top={0}
            width={578}
            borderWidth={1}
            px='l'
            pb='l'
            borderColor='grey02'
            backgroundColor='white'
            style={{ paddingTop: 21 }}>
            <Box flexDirection='row' marginBottom='l'>
              <Box flex={1}>
                <Text variant='heading2'>
                  {editProjectId
                    ? t('models:projects.edit.title')
                    : t('models:projects.create.title')}
                </Text>
              </Box>
              <Box
                width={20}
                height={32}
                alignItems='center'
                justifyContent='center'>
                <Icon
                  accessibilityLabel='Close'
                  name='X'
                  variant='m'
                  onPress={() => {
                    setIsCreateProjectOpen(false);
                    setEditProjectId('');
                  }}
                  color='textPrimary'
                />
              </Box>
            </Box>
            <CreateProject externalNavigate={navigation} />
          </Box>
        </NavigationContainer>
      )}
      {isBottomBannerShow && (
        <ChatEmptyState
          onClose={() => {
            setIsBottomBannerShow(false);
          }}
          onContentPress={() => {
            setIsModalShow(true);
            setIsBottomBannerShow(false);
          }}
          type={EmptyStateType.Banner}></ChatEmptyState>
      )}
      {isModalShow && (
        <WebModal
          onClose={() => {
            setIsModalShow(false);
            setIsBottomBannerShow(true);
          }}
          visible={isModalShow}
          width={400}
          height={413}>
          <ChatEmptyState
            onClose={() => {
              setIsModalShow(false);
              setIsBottomBannerShow(true);
            }}
            type={EmptyStateType.Popup}></ChatEmptyState>
        </WebModal>
      )}
    </Box>
  );
};
