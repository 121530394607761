/* eslint-disable import/no-default-export */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ParamListBase,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import { Field, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AlertButton } from 'react-native';
import { InferType } from 'yup';

import { Alert } from '@components/Alert/index.web';
import { Box, Text } from '@components/Restyle';
import Button from '@components/shared/Button/Button';
import Icon from '@components/shared/Icon/Icon';
import TextField from '@components/shared/TextField/TextField';
import { AuthStackScreenProps } from '@navigation/auth/auth-stack';
import { passwordResetSchema } from '@schemas/passwordResetSchema';
import theme from '@themes/theme';
import { getApiConfig } from '@utils/getApiConfig';

export type PasswordResetFormValues = InferType<typeof passwordResetSchema>;
export const ResetPassword: React.FC<ParamListBase> = () => {
  const navigation = useNavigation();

  const { params } =
    useRoute<AuthStackScreenProps<'password-reset'>['route']>();
  const token = params?.reset_password_token ?? '';

  const navigateToLogIn = () => {
    navigation.navigate('login');
  };

  if (!token || token.length === 1) {
    navigateToLogIn();
    return null;
  }

  const apiConfig = getApiConfig();
  const { t } = useTranslation('models');

  const [pwdFocused, setPwdFocused] = useState(false);
  const [pwdHas8Chars, setPwdHas8Chars] = useState(false);
  const [pwdHas1Number, setPwdHas1Number] = useState(false);
  const [pwdHas1Uppercase, setPwdHas1Uppercase] = useState(false);
  const [pwdHas1SpecialChar, setPwdHas1SpecialChar] = useState(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const [confrimPwdFocused, setconfrimPwdFocused] = useState(false);

  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);

  const resetPassword = async (values: PasswordResetFormValues) => {
    const { token, password, confirmPassword: password_confirmation } = values;
    const resetEndpoint = `${apiConfig.apiUrl}/api/v1/reset_password.json`;

    await fetch(resetEndpoint, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user: {
          token,
          password,
          password_confirmation,
        },
      }),
    })
      .then((response) => response.json())
      .then(async (response) => {
        if (response?.success ?? false)
          navigation.navigate('login', { fromReset: true });
        else resetPasswordErrorAlert();
      })
      .catch(() => resetPasswordErrorAlert());
  };

  const resetPasswordErrorAlert = () => {
    const title = t('shared:error');
    const message = t('models:resetPassword.error.message');
    const okayButton: AlertButton = {
      text: t('shared:okay'),
      style: 'cancel',
      onPress: navigateToLogIn,
    };
    Alert.alert(title, message, [okayButton]);
  };

  const initialValues: PasswordResetFormValues = {
    token: token ?? '',
    password: '',
    confirmPassword: '',
  };

  return (
    <Formik
      validateOnMount={true}
      initialValues={initialValues}
      onSubmit={async (values) => resetPassword(values)}>
      {({ submitForm, values }) => {
        useEffect(() => {
          checkPassword();
        }, [values.password]);

        const getCheckNode = (flg: boolean, label: string) => {
          return (
            <Box flexDirection='row' mb='xxs'>
              {flg ? (
                <Icon name='Check' variant='s' color='greenSecondary' />
              ) : (
                <Icon name='Dot' variant='s' />
              )}
              <Text
                ml='xxxs'
                variant='metadata'
                color={flg ? 'greenSecondary' : 'textSecondary'}>
                {label}
              </Text>
            </Box>
          );
        };

        const checkPassword = () => {
          const password = values.password;

          if (!password || password.length === 0) {
            pwdHas8Chars && setPwdHas8Chars(false);
            pwdHas1Number && setPwdHas1Number(false);
            pwdHas1Uppercase && setPwdHas1Uppercase(false);
            pwdHas1SpecialChar && setPwdHas1SpecialChar(false);
          } else {
            const pwdHas8Chars = /.{8,}/.test(password);
            setPwdHas8Chars(pwdHas8Chars);

            const pwdHas1Number = /[0-9]/.test(password);
            setPwdHas1Number(pwdHas1Number);

            const pwdHas1Uppercase = /[A-Z]/.test(password);
            setPwdHas1Uppercase(pwdHas1Uppercase);

            const pwdHas1SpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
            setPwdHas1SpecialChar(pwdHas1SpecialChar);
          }
        };

        const isValid =
          values.password.length > 0 &&
          pwdHas8Chars &&
          pwdHas1Number &&
          pwdHas1Uppercase &&
          pwdHas1SpecialChar &&
          values.confirmPassword === values.password;

        const pwdColor = () => {
          if (values.confirmPassword.length === 0) {
            if (!confrimPwdFocused) return theme.colors.grey02;
            else return theme.colors.black;
          } else if (values.password === values.confirmPassword) {
            return theme.colors.greenSecondary;
          } else {
            return theme.colors.alertRed;
          }
        };
        return (
          <Box backgroundColor='grey01' height='100%'>
            <Box alignItems='center' justifyContent='center' height='100%'>
              <Box
                alignItems='center'
                borderRadius='m'
                backgroundColor='white'
                style={{
                  paddingVertical: 32,
                  paddingHorizontal: 40,
                  shadowColor: 'black',
                  shadowOpacity: 0.05,
                  shadowOffset: { width: 0, height: 25 },
                  shadowRadius: 25,
                  position: 'absolute',
                  width: 492,
                  overflow: 'hidden',
                }}>
                <Icon name='Logo' width={142} height={43} />
                <Text
                  style={{ marginTop: 42, marginBottom: 26 }}
                  variant='heading2'
                  color='black'>
                  {t('shared:resetPassword')}
                </Text>

                <Box width='100%'>
                  <Text
                    marginBottom='xs'
                    variant='labelSmall'
                    style={{ lineHeight: 16 }}
                    color='textPrimary'>
                    {t('onBoarding.modal.newPassword')}
                  </Text>
                  <Field
                    component={TextField}
                    marginBottom='xs'
                    placeholder={t('onBoarding.modal.enterPassword')}
                    placeholderTextColor='grey04'
                    name='password'
                    suffix={
                      <Icon
                        name={showPassword ? 'Eye' : 'EyeOff'}
                        variant='l'
                        color='textPrimary'
                        onPress={() => setShowPassword(!showPassword)}
                      />
                    }
                    textInputProps={{
                      autoCapitalize: 'none',
                      autoCorrect: false,
                      secureTextEntry: !showPassword,
                    }}
                    accessibilityLabel={t('onBoarding.modal.newPassword')}
                    onFocus={(b: boolean) => setPwdFocused(b)}
                    validateOnChange={true}
                  />

                  {pwdFocused && (
                    <Box alignSelf='flex-start' mb='xxs'>
                      <Text variant='metadata' color='textSecondary' mb='xxs'>
                        {t('models:onBoarding.yourPasswordMustContain')}
                      </Text>
                      {getCheckNode(
                        pwdHas8Chars,
                        t('models:onBoarding.atLeast8Characters')
                      )}
                      {getCheckNode(
                        pwdHas1Number,
                        t('models:onBoarding.atLeast1Number')
                      )}
                      {getCheckNode(
                        pwdHas1Uppercase,
                        t('models:onBoarding.atLeast1UppcaseLetter')
                      )}
                      {getCheckNode(
                        pwdHas1SpecialChar,
                        t('models:onBoarding.atLeast1SpecialCharacter')
                      )}
                    </Box>
                  )}
                  <Text
                    marginTop='m'
                    marginBottom='xs'
                    variant='labelSmall'
                    style={{ lineHeight: 16 }}
                    color='textPrimary'>
                    {t('onBoarding.modal.confirmThePassword')}
                  </Text>
                  <Field
                    component={TextField}
                    placeholder={t('onBoarding.modal.enterPassword')}
                    placeholderTextColor='grey04'
                    name='confirmPassword'
                    style={{ borderColor: 'red' }}
                    onFocus={(b: boolean) => setconfrimPwdFocused(b)}
                    onBlur={() => setconfrimPwdFocused(false)}
                    isLarge
                    suffix={
                      <Icon
                        name={showConfirmPassword ? 'Eye' : 'EyeOff'}
                        variant='l'
                        color='textPrimary'
                        onPress={() =>
                          setShowConfirmPassword(!showConfirmPassword)
                        }
                      />
                    }
                    textInputProps={{
                      autoCapitalize: 'none',
                      autoCorrect: false,
                      secureTextEntry: !showConfirmPassword,
                    }}
                    accessibilityLabel={t(
                      'onBoarding.modal.confirmThePassword'
                    )}
                    customBorderColor={pwdColor()}
                    validateOnChange={true}
                  />

                  <Button
                    onPress={() => submitForm()}
                    fullWidth
                    isSmall={true}
                    height={48}
                    disabled={!isValid}
                    backgroundColor={isValid ? 'black' : 'grey02'}
                    accessibilityLabel={t('onBoarding.modal.changePassword')}>
                    <Text
                      variant='labelSmall'
                      color={isValid ? 'white' : 'grey04'}
                      style={{ lineHeight: 16 }}>
                      {t('onBoarding.modal.changePassword')}
                    </Text>
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        );
      }}
    </Formik>
  );
};
