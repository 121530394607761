import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity, Animated, Platform } from 'react-native';

import RadioButton from '@components/RadioButton/RadioButton';
import { Box, Text } from '@components/Restyle';
import Icon from '@components/shared/Icon/Icon';
import { CheckBoxButton } from '@components/Web/CheckBoxButton';
import { TaskChecklist as ChecklistType } from '@graphql/generated';

type AnimatedSection = {
  height: Animated.Value;
  opacity: Animated.Value;
  rotation: Animated.Value;
  id: string;
  isOpen: boolean;
};

type InitialHeight = {
  height: number;
  id: string;
};

interface TaskCompletedChecklistProps {
  completedChecklist: ChecklistType[];
  onSelect: (item: ChecklistType) => void;
}

const TaskCompletedChecklist: React.FC<TaskCompletedChecklistProps> = ({
  completedChecklist,
  onSelect,
}) => {
  const { t } = useTranslation('models');

  const [showCompletedChecklist, setShowCompletedChecklist] = useState(true);

  const [animatedSections, setAnimatedSections] = useState<AnimatedSection[]>(
    []
  );
  const [initialHeights, setInitialHeights] = useState<InitialHeight[]>([]);
  const [isAnimating, setIsAnimating] = useState<boolean>(false);
  const [isInitialFinsh, setIsInitialFinsh] = useState<boolean>(false);
  const id = '1';

  const itemDivider = () => {
    return <Box height={1} backgroundColor='grey02' mx='m' />;
  };

  const sectionHeader = () => {
    return (
      <>
        <Box
          alignItems='center'
          flexDirection='row'
          justifyContent='center'
          borderRadius='xxs'>
          <Text variant='labelSmall' color='grey04'>
            {t('tasks.checklist.checkedItemWithCount', {
              count: completedChecklist.length,
            })}
          </Text>
        </Box>
        <Box flex={1}></Box>
      </>
    );
  };

  const renderItemForCompleted = (item: ChecklistType) => {
    return (
      <Box
        accessibilityLabel='Completed Item'
        flex={1}
        flexDirection='row'
        alignItems='center'
        justifyContent='flex-start'
        paddingVertical='m'
        marginLeft='m'
        paddingRight='l'
        backgroundColor='white'>
        <Box marginHorizontal='xs' paddingLeft='xxxs'>
          {Platform.OS === 'web' ? (
            <TouchableOpacity
              accessibilityLabel={item.name + ' Checkbox'}
              onPress={() => {
                onSelect(item);
              }}>
              <CheckBoxButton checked={item.completedAt} />
            </TouchableOpacity>
          ) : (
            <RadioButton
              onPress={() => {
                onSelect(item);
              }}
              isSelected={item.completedAt}
            />
          )}
        </Box>
        <Box pr='xxl' marginLeft='xs'>
          <Text
            accessibilityLabel={item.name}
            variant='body'
            textDecorationLine='line-through'
            color='grey04'>
            {item.name}
          </Text>
        </Box>
      </Box>
    );
  };

  const getRotation = (id: string) => {
    const section = animatedSections.find((s) => s.id === id);

    if (section) {
      return {
        transform: [
          {
            rotate: section.rotation.interpolate({
              inputRange: [0, 1],
              outputRange: ['0deg', '180deg'],
            }),
          },
        ],
      };
    }
    return undefined;
  };

  const animate = (id: string) => {
    setIsInitialFinsh(!isInitialFinsh);
    setShowCompletedChecklist(!showCompletedChecklist);

    const section = animatedSections.find((s) => s.id === id);

    if (section) {
      const { isOpen } = section;
      setIsAnimating(true);
      const newHeight = isOpen
        ? 0
        : initialHeights.find((i) => i.id === id)?.height ?? 0;
      const newOpacity = isOpen ? 0 : 1;
      const newRotation = isOpen ? 0 : 1;

      Animated.parallel([
        Animated.timing(section.height, {
          toValue: newHeight,
          duration: 200,
          useNativeDriver: false,
        }),
        Animated.timing(section.opacity, {
          toValue: newOpacity,
          duration: 50,
          useNativeDriver: false,
        }),
        Animated.timing(section.rotation, {
          toValue: newRotation,
          duration: 200,
          useNativeDriver: false,
        }),
      ]).start(() => {
        setIsAnimating(false);
        const sectionIndex = animatedSections.findIndex((s) => s.id === id);
        const newState = [...animatedSections];
        newState[sectionIndex].isOpen = !section.isOpen;
        setAnimatedSections(newState);
      });
    }
  };

  const getAnimatedStyles = (id: string) => {
    const section = animatedSections.find((s) => s.id === id);
    if (section) {
      const { isOpen } = section;

      const open = Platform.OS === 'web' ? isOpen && 1 : 1;
      return {
        height: section.height,
        opacity: section.opacity,
        flex: open,
      };
    }
    return undefined;
  };

  return (
    <>
      <TouchableOpacity onPress={() => animate(id)} disabled={isAnimating}>
        <Box flexDirection='row' alignItems='center' marginTop='m' padding='m'>
          {sectionHeader()}
          <Animated.View style={!isInitialFinsh && getRotation(id)}>
            <Icon name='ChevronDown' color='black' />
          </Animated.View>
        </Box>
      </TouchableOpacity>
      <Animated.View
        onLayout={(e) => {
          const height = e.nativeEvent.layout.height;
          const initialHeightIndex = initialHeights?.findIndex(
            (a) => a.id === id
          );

          if (initialHeightIndex === undefined || initialHeightIndex === -1) {
            setAnimatedSections((h) => [
              ...h,
              {
                height: new Animated.Value(height),
                opacity: new Animated.Value(1),
                rotation: new Animated.Value(1),
                id: id,
                isOpen: true,
              },
            ]);

            setInitialHeights((h) => [...h, { height, id: id }]);
          } else if (height > initialHeights[initialHeightIndex].height) {
            // update height if the height is higher than current height
            const newHeights = [...initialHeights];
            newHeights[initialHeightIndex].height = height;
            setInitialHeights(newHeights);
          }
        }}
        style={isInitialFinsh && getAnimatedStyles(id)}>
        {showCompletedChecklist && (
          <Animated.FlatList
            data={completedChecklist}
            keyExtractor={(item: ChecklistType) => item.id.toString()}
            renderItem={({ item }) => renderItemForCompleted(item)}
            ListHeaderComponent={() => itemDivider()}
            ItemSeparatorComponent={() => itemDivider()}
            ListFooterComponent={() => itemDivider()}
          />
        )}
      </Animated.View>
    </>
  );
};

export default TaskCompletedChecklist;
