import React from 'react';

import { ActivityItemDocument } from '@components/ActivityList/ActivityItemDocument';
import ChatMessage from '@components/Chat/ChatMessage';
import TagsCollection from '@components/Chat/TagsCollection';
import {
  Audit,
  Attachment,
  ProjectUser,
  Task,
  TaskUser,
  EntityUser,
  Message,
  Document,
} from '@graphql/generated';

export const getProjectActivityMessage = (
  event: ProjectActivityType,
  projectRole?: string,
  role?: string,
  messageBody?: string
) => {
  switch (event) {
    case ProjectActivityType.ProjectCreated:
      return 'Created this project';
    case ProjectActivityType.ProjectAssigned:
      return 'Added as Project ' + projectRole;
    case ProjectActivityType.ProjectUnassigned:
      return 'Unassigned from this project';
    case ProjectActivityType.ProjectUnassignedSelf:
      return 'Left this Project';
    case ProjectActivityType.TaskCompleted:
      return 'Completed this task';
    case ProjectActivityType.TaskAssigned:
      return 'Added as Task ' + role;
    case ProjectActivityType.TaskCreated:
      return 'Created this task';
    case ProjectActivityType.TaskCompletedRestore:
      return 'Restored this task';
    case ProjectActivityType.TaskUnassigned:
      return 'Unassigned from this task';
    case ProjectActivityType.TaskUnassignedSelf:
      return 'Left this task';
    case ProjectActivityType.ProjectDocument:
      return 'Added file to this project';
    case ProjectActivityType.TaskDocument:
      return 'Added file to this task';
    case ProjectActivityType.MessageSent:
      return messageBody;
    default:
      return '';
  }
};

export enum ProjectActivityType {
  TaskDocument = 'TaskDocument',
  ProjectDocument = 'ProjectDocument',
  ProjectCreated = 'ProjectCreated',
  ProjectAssigned = 'ProjectAssigned',
  ProjectUnassigned = 'ProjectUnassigned',
  ProjectUnassignedSelf = 'ProjectUnassignedSelf',
  ProjectCompleted = 'ProjectCompleted',
  MessageSent = 'MessageSent',
  TaskCreated = 'TaskCreated',
  TaskAssigned = 'TaskAssigned',
  TaskUnassigned = 'TaskUnassigned',
  TaskUnassignedSelf = 'TaskUnassignedSelf',
  TaskCompleted = 'TaskCompleted',
  TaskCompletedRestore = 'TaskCompletedRestore',
  Default = '',
}

const allowedActivities = [
  ProjectActivityType.ProjectDocument,
  ProjectActivityType.ProjectCreated,
  ProjectActivityType.ProjectAssigned,
  ProjectActivityType.ProjectUnassigned,
  ProjectActivityType.ProjectUnassignedSelf,
  ProjectActivityType.TaskCompleted,
  ProjectActivityType.TaskAssigned,
  ProjectActivityType.TaskCreated,
  ProjectActivityType.TaskCompletedRestore,
  ProjectActivityType.TaskUnassigned,
  ProjectActivityType.TaskUnassignedSelf,
  ProjectActivityType.MessageSent,
];

export const filteredProjectAudits = (audits: Audit[]) => {
  return audits.filter((audit) =>
    allowedActivities.includes(audit.event as ProjectActivityType)
  );
};

type ProjectActivityItemProps = {
  audit: Audit;
};

const ProjectActivityItem: React.FC<ProjectActivityItemProps> = ({ audit }) => {
  const { attachment, user } = audit;

  const isTaskAssigned = audit.event === 'TaskAssigned';
  const isTaskUnassigned = audit.event === 'TaskUnassigned';
  const isTaskUnassignedSelf = audit.event === 'TaskUnassignedSelf';

  const isMessageType = (item: Attachment): item is Message =>
    item.__typename === 'Message';

  const _isProjectType = (item: Attachment): item is ProjectUser =>
    item.__typename === 'Project';

  const isTaskType = (item: Attachment): item is Task =>
    item.__typename === 'Task';

  const isTaskUserType = (item: Attachment): item is TaskUser =>
    item.__typename === 'TaskUser';

  const isEntityType = (item: Attachment): item is EntityUser =>
    item.__typename === 'EntityUser';

  if (!attachment) {
    return null;
  }

  const isDocumentType = (item: Attachment): item is Document =>
    item.__typename === 'Document';

  if (isMessageType(attachment)) {
    return <ChatMessage message={attachment} isActivityItem />;
  }

  if (isDocumentType(attachment)) {
    return (
      <ActivityItemDocument attachment={attachment}></ActivityItemDocument>
    );
  }

  if (isTaskType(attachment)) {
    return (
      <TagsCollection
        showAuthor={false}
        tagsCollection={{
          tasks: [attachment],
          project: attachment.project,
          author: user,
        }}
      />
    );
  }

  if (isTaskUserType(attachment)) {
    return (
      <TagsCollection
        showAuthor={false}
        tagsCollection={{
          tasks: [attachment.task],
          project: attachment.task.project,
          author: user,
        }}
      />
    );
  }

  if (isEntityType(attachment)) {
    if (isTaskAssigned || isTaskUnassigned || isTaskUnassignedSelf)
      return (
        <TagsCollection
          showAuthor={false}
          tagsCollection={{
            tasks: attachment.entityTask ? [attachment.entityTask] : [],
            project: attachment.entityProject || attachment.entityTask?.project,
            author: user,
          }}
        />
      );
  }
  return null;
};

export default ProjectActivityItem;
