import * as Clipboard from 'expo-clipboard';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity, StyleSheet } from 'react-native';
import { useToast } from 'react-native-toast-notifications';

import { Box, Text } from '@components/Restyle';
import Icon from '@components/shared/Icon/Icon';
import { useCreateTeamInvitationMutation } from '@graphql/generated';
import theme from '@themes/theme';

interface TeamActionListProps {
  teamId: string;
  teamName: string;
  isOwnerOrAdmin?: boolean;
  setShowAddMembersModal: (show: boolean) => void;
}

const TeamActionList: React.FC<TeamActionListProps> = ({
  teamId,
  teamName,
  isOwnerOrAdmin,
  setShowAddMembersModal,
}) => {
  const { t } = useTranslation();
  const toast = useToast();

  const [createTeamInvitationMutation] = useCreateTeamInvitationMutation({
    onCompleted: ({ createTeamInvitation }) => {
      if (createTeamInvitation) {
        const formattedLink =
          createTeamInvitation.replace(
            'register/register-with-email',
            'invited-user'
          ) + `&type=team`;
        copyToClipboard(formattedLink);
      }
    },
  });

  const copyToClipboard = async (link: string) => {
    if (!link) return; // Early return if link is falsy
    const inviteLink = `You’re invited to join our new team at ${teamName}. Click the link below to get started: `;
    await Clipboard.setStringAsync(inviteLink + link);
    navigator.share({
      title: inviteLink,
      url: link,
    });

    toast.show('Copied', {
      placement: 'center',
      duration: 3000,
    });
  };

  return (
    <Box flexDirection='row' alignItems='center' mx='m' mt='xs' mb='m'>
      {isOwnerOrAdmin && (
        <TouchableOpacity
          onPress={() => {
            setShowAddMembersModal(true);
          }}
          style={styles.marginRight}>
          <Box
            accessibilityLabel={t('shared:addMembers')}
            flexDirection='row'
            alignItems='center'>
            <Icon name='UserPlus' variant='l' backgroundColor='grey01' />
            <Text variant='labelEmphasized' ml='xs'>
              {t('shared:addMembers')}
            </Text>
          </Box>
        </TouchableOpacity>
      )}

      <TouchableOpacity
        onPress={() => {
          createTeamInvitationMutation({ variables: { id: teamId } });
        }}
        style={styles.marginRight}>
        <Box
          accessibilityLabel={t('shared:copyLinkToInvite')}
          flexDirection='row'
          alignItems='center'>
          <Icon name='Link' variant='l' backgroundColor='grey01' />
          <Text variant='labelEmphasized' ml='xs'>
            {t('shared:copyLinkToInvite')}
          </Text>
        </Box>
      </TouchableOpacity>
    </Box>
  );
};

const styles = StyleSheet.create({
  marginRight: {
    marginRight: theme.spacing.l,
  },
});

export default TeamActionList;
