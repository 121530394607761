import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import {
  createStackNavigator,
  StackScreenProps,
} from '@react-navigation/stack';
import * as Clipboard from 'expo-clipboard';
import { useTranslation } from 'react-i18next';
import { Platform, StyleSheet } from 'react-native';
import { useToast } from 'react-native-toast-notifications';

import CustomTabBar from '@components/CustomTabBar/CustomTabBar';
import { ListScreenActionHeader } from '@components/ListScreenActionHeader';
import { Box } from '@components/Restyle';
import Icon from '@components/shared/Icon/RestyleIcon';
import { useWebDrawer } from '@components/Web/Drawer/WebDrawerContext';
import { useCreateInviteMutation, User } from '@graphql/generated';
import useFeatureFlag from '@hooks/useFeatureFlag';
import { ContactGroupList } from '@screens/Contacts/ContactGroupList.web';
import { Contacts } from '@screens/Contacts/Contacts.web';
import images from '@themes/images';
import theme from '@themes/theme';

export type ContactsTabBarParamList = {
  contacts?: { userId: User['id'] };
  group?: undefined;
};

export type ContactsTabStackScreenProps<
  T extends keyof ContactsTabBarParamList
> = StackScreenProps<ContactsTabBarParamList, T>;

const Stack = createStackNavigator<ContactsTabBarParamList>();
const Tab = createMaterialTopTabNavigator();

export const ContactsStack: React.FC = () => {
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
      }}>
      <Stack.Screen name='contacts' component={ContactsTabNavigator} />
    </Stack.Navigator>
  );
};

const ContactsTabNavigator: React.FC = () => {
  const { t } = useTranslation('models');
  const toast = useToast();
  const { listFeatureFlags } = useFeatureFlag();

  // Find the feature flag with the name "contact_groups"
  const contactGroupsFeature = listFeatureFlags.find(
    (flag: { name: string; enabled: boolean }) => flag.name === 'contact_groups'
  );

  // Check if the feature is enabled
  const isContactGroupsEnabled = contactGroupsFeature?.enabled;

  console.log('isContactGroupsEnabled====', isContactGroupsEnabled);

  const { setIsContactWebPanelOpen, setEditContactId, setIdContactDetailOpen } =
    useWebDrawer();
  const [createInviteMutation] = useCreateInviteMutation({
    variables: {},
    onCompleted: (data) => {
      copyToClipboard(
        (data?.createInvite ?? '').replace(
          'register/register-with-email',
          'invited-user'
        )
      );
    },
  });

  const copyToClipboard = async (link: string) => {
    toast.show('Copied', {
      duration: 2000,
    });

    await Clipboard.setStringAsync(link || '');
  };

  const renderIcon = (iconName: keyof typeof images) => {
    return (
      <Box style={[styles.outerPerson]}>
        <Icon name={iconName} />
      </Box>
    );
  };

  return (
    <Box flex={1}>
      <Box marginLeft='l' flexDirection='row' marginVertical='m'>
        <ListScreenActionHeader
          createNewButtonProps={{
            onPress: createInviteMutation,
            text: t('models:contacts.inviteFriends'),
            prefix: renderIcon('UserCircle'),
            variant: 'editBold',
            style: {
              backgroundColor: 'background',
              borderWidth: 0,
            },
          }}
        />
        {isContactGroupsEnabled && (
          <ListScreenActionHeader
            createNewButtonProps={{
              onPress: () => {
                setEditContactId('');
                setIdContactDetailOpen('');
                setIsContactWebPanelOpen(true);
              },
              text: t('models:contacts.createGroup'),
              prefix: renderIcon('Users'),
              variant: 'editBold',
              style: {
                backgroundColor: 'background',
                borderWidth: 0,
                marginLeft: 's',
              },
            }}
          />
        )}
      </Box>
      <Tab.Navigator
        screenOptions={{ swipeEnabled: Platform.OS !== 'web' }}
        sceneContainerStyle={{
          backgroundColor: 'transparent',
        }}
        tabBar={(props) => (
          <Box
            flexDirection='row'
            alignItems='center'
            paddingHorizontal='m'
            paddingBottom='xs'
            maxHeight={50}>
            <CustomTabBar {...props} spacing={theme.spacing.l} />
          </Box>
        )}>
        <Tab.Screen
          options={{
            tabBarLabel: 'Contacts',
          }}
          name='contacts'
          component={Contacts}
        />
        {isContactGroupsEnabled && (
          <Tab.Screen
            options={{
              tabBarLabel: 'Group',
            }}
            name='group'
            component={ContactGroupList}
          />
        )}
      </Tab.Navigator>
    </Box>
  );
};

const styles = StyleSheet.create({
  outerPerson: {
    width: 40,
    height: 40,
    backgroundColor: theme.colors.grey01,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.borderRadii.xs,
    marginRight: theme.spacing.s,
  },
});
