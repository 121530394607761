import React, { createContext, useContext, useState } from 'react';

import { ProjectMemberRole, Team, User } from '@graphql/generated';
import { ProjectSectionOptions } from '@hooks/useTasksFilter';
import { PickEnum } from '@root/types';

export type OwnerFilter = {
  type: 'ProjectsFilterOwner';
  name: string;
  value: User['id'];
};

export type UserFilter = {
  type: 'ProjectssFilterUser';
  name: string;
  value: User['id'];
};

export type TeamFilter = {
  type: 'ProjectsFilterTeam';
  name: string;
  value: Team['id'];
};

export type SectionFilter = {
  type: 'ProjectFilterSectionOption';
  name: string;
  value: ProjectSectionOptions;
};

export type FilterItem = OwnerFilter | UserFilter | TeamFilter | SectionFilter;
export type FilterItems = FilterItem[];

export type FilterableProjectRolesOptions = PickEnum<
  ProjectMemberRole,
  | ProjectMemberRole.Owner
  | ProjectMemberRole.Admin
  | ProjectMemberRole.Editor
  | ProjectMemberRole.Viewer
>;

type projectsFilterContext = {
  sectionOption: ProjectSectionOptions;
  setSectionOption: React.Dispatch<React.SetStateAction<ProjectSectionOptions>>;

  roleFilter: FilterableProjectRolesOptions | undefined;
  setRoleFilter: React.Dispatch<
    React.SetStateAction<FilterableProjectRolesOptions | undefined>
  >;
  isMapView: boolean;
  setMapView: React.Dispatch<boolean>;
  ownerFilters: OwnerFilter[];
  setOwnerFilters: React.Dispatch<React.SetStateAction<OwnerFilter[]>>;
  userFilters: UserFilter[];
  setUserFilters: React.Dispatch<React.SetStateAction<UserFilter[]>>;
  teamFilters: TeamFilter[];
  setTeamFilters: React.Dispatch<React.SetStateAction<TeamFilter[]>>;
};

const projectsFilterContext = createContext<projectsFilterContext | undefined>(
  undefined
);

export const ProjectsFilterProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { Provider } = projectsFilterContext;

  const [roleFilter, setRoleFilter] = useState<FilterableProjectRolesOptions>();
  const [isMapView, setMapView] = useState(false);
  const [ownerFilters, setOwnerFilters] = useState<OwnerFilter[]>([]);
  const [userFilters, setUserFilters] = useState<UserFilter[]>([]);
  const [teamFilters, setTeamFilters] = useState<TeamFilter[]>([]);
  const [sectionOption, setSectionOption] = useState<ProjectSectionOptions>(
    ProjectSectionOptions.Active
  );
  return (
    <Provider
      value={{
        sectionOption,
        setSectionOption,
        roleFilter,
        setRoleFilter,
        isMapView,
        setMapView,
        userFilters,
        setUserFilters,
        teamFilters,
        setTeamFilters,
        ownerFilters,
        setOwnerFilters,
      }}>
      {children}
    </Provider>
  );
};

export const useProjectsFilter = (): projectsFilterContext => {
  const context = useContext(projectsFilterContext);
  if (context === undefined) {
    throw new Error('useProjectsFilter must be used within a Provider');
  }
  return context;
};
