import get from 'lodash.get';
import sortBy from 'lodash.sortby';
import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList } from 'react-native';

import EmptyStateNoContactGroup from '@components/EmptyState/EmptyStateNoContactGroup.web';
import { Box, Text } from '@components/Restyle';
import Icon from '@components/shared/Icon/Icon';
import { useListGroupsQuery } from '@graphql/generated';
import { ContactGroupCard } from '@screens/Contacts/ContactGroupCard.web';
import theme from '@themes/theme';

export const ContactGroupList: React.FC = () => {
  const { t } = useTranslation('models');
  const [sortConfig, setSortConfig] = useState({
    isSortName: false,
    isSortNameAsc: false,
  });

  const { data } = useListGroupsQuery();

  const handleSortNameAsc = useCallback(() => {
    setSortConfig((prev) => ({
      isSortName: true,
      isSortNameAsc: !prev.isSortNameAsc,
    }));
  }, []);

  const handleSortNameDesc = useCallback(() => {
    setSortConfig(() => ({
      isSortName: true,
      isSortNameAsc: false,
    }));
  }, []);

  const alphabetizedMembers = sortBy(data?.listGroups, [
    (item) => get(item, 'name')?.toLowerCase(),
  ]);

  const alphabetizedMembers1 = sortConfig.isSortNameAsc
    ? alphabetizedMembers
    : alphabetizedMembers.reverse();

  const getHeader = () => {
    return (
      <Box
        flexDirection='row'
        alignItems='center'
        paddingHorizontal='m'
        paddingVertical='xs'
        borderRadius='xs'
        backgroundColor='grey01'
        marginHorizontal='l'>
        <Box flex={1} marginLeft='s' flexDirection='row' alignItems='center'>
          <Text variant='labelRequired' color='grey04'>
            {t('projects.members.name')}
          </Text>
          <Icon
            marginLeft='xs'
            variant='xxs'
            name='ArrowUpNarrow'
            color={
              sortConfig.isSortName && sortConfig.isSortNameAsc
                ? 'greenSecondary'
                : 'grey02'
            }
            style={{ marginRight: -3 }}
            onPress={handleSortNameAsc}
          />
          <Icon
            variant='xxs'
            name='ArrowDownNarrow'
            color={
              sortConfig.isSortName && !sortConfig.isSortNameAsc
                ? 'greenSecondary'
                : 'grey02'
            }
            style={{ marginLeft: -3 }}
            onPress={handleSortNameDesc}
          />
        </Box>
        <Box flex={1} flexDirection='row' justifyContent='flex-start'>
          <Text variant='labelRequired' color='grey04'>
            {t('contacts.team1')}
          </Text>
        </Box>
        <Box
          flex={1}
          flexDirection='row'
          marginLeft='listFooter'
          justifyContent='flex-start'>
          <Text variant='labelRequired' color='grey04'>
            {t('contacts.projects')}
          </Text>
        </Box>
        <Box flex={0.5} flexDirection='row' justifyContent='center'>
          <Text variant='labelRequired' color='grey04'>
            {t('contacts.users')}
          </Text>
        </Box>
        <Box flex={0.5} flexDirection='row' justifyContent='flex-end'>
          <Text variant='labelRequired' color='grey04'>
            {t('contacts.actions')}
          </Text>
        </Box>
      </Box>
    );
  };

  return (
    <Box flex={1}>
      {alphabetizedMembers1 && alphabetizedMembers1.length > 0 ? (
        <Box>
          {getHeader()}
          <Box flex={1} alignContent='center' justifyContent='center'>
            <FlatList
              showsVerticalScrollIndicator={false}
              data={alphabetizedMembers1 || []}
              style={{ paddingHorizontal: theme.spacing.l }}
              renderItem={({ item }) => (
                <Box key={item.id}>
                  <ContactGroupCard listGroups={item} />
                </Box>
              )}
              keyExtractor={(item) => item.id.toString()} // Ensure unique key
              ListHeaderComponent={() => <Box marginTop='xs' />}
              ListFooterComponent={() => <Box marginBottom='listFooter' />}
            />
          </Box>
        </Box>
      ) : (
        <EmptyStateNoContactGroup />
      )}
    </Box>
  );
};
