import React, { useEffect, useState } from 'react';
import { TouchableOpacity } from 'react-native';

import CachedImage from '@components/CachedImage/CachedImage';
import { Box, Text } from '@components/Restyle';
import Icon from '@components/shared/Icon/Icon';
import { LocalFile, Team } from '@graphql/generated';
import useMe from '@hooks/useMe';
import theme from '@themes/theme';

interface TeamListItemProps {
  team: Pick<
    Team,
    'id' | 'name' | 'avatarFile' | 'avatar' | 'usersCount' | 'personal'
  >;
  onSelect: () => void;
}

export const TeamListItem: React.FC<TeamListItemProps> = ({
  team,
  onSelect,
}) => {
  const { name, usersCount, avatar, personal, id } = team;
  const { me } = useMe();

  const [primaryTeam, setPrimaryTeam] = useState(me?.primaryTeamId);

  useEffect(() => {
    if (me?.primaryTeamId !== primaryTeam) {
      setPrimaryTeam(me?.primaryTeamId);
    }
  }, [me?.primaryTeamId, primaryTeam]);

  const isPrimary = primaryTeam === id;

  const renderAvatar = () => {
    if (team.avatarFile)
      return (
        <CachedImage
          image={team.avatarFile as unknown as LocalFile}
          width={40}
          height={40}
          borderRadius={theme.spacing.xs}
        />
      );

    if (personal)
      return (
        <Icon
          variant='xxl'
          padding='xxs'
          name='Office'
          backgroundColor='greenSecondaryMild'
          isCircle={false}
        />
      );
    return (
      <Icon
        variant='xxl'
        width={40}
        height={40}
        padding='none'
        name={(avatar as keyof typeof Icon) || 'TTProject'}
        backgroundColor='black'
        isCircle={false}
      />
    );
  };
  return (
    <TouchableOpacity onPress={onSelect} accessibilityLabel={name}>
      <Box flexDirection='row' alignItems='center'>
        <Box borderRadius='xs'>{renderAvatar()}</Box>

        <Box flex={1} ml='xs'>
          <Text
            variant='labelSmall'
            color='textPrimary'
            numberOfLines={1}
            ellipsizeMode='tail'>
            {name}
          </Text>
          <Box flexDirection='row' alignItems='center'>
            {isPrimary && (
              <Icon variant='m' color='textSecondary' name='StarYellow' />
            )}
            <Text variant='metadata' color='textSecondary'>
              {usersCount} Members
            </Text>
          </Box>
        </Box>
      </Box>
    </TouchableOpacity>
  );
};
