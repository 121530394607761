import { FlashList, ViewToken } from '@shopify/flash-list';
import dayjs from 'dayjs';
import debounce from 'lodash.debounce';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Animated } from 'react-native';

import ChatMessage from '@components/Chat/ChatMessage.web';
import { ChatSearchText } from '@components/Chat/ChatSearchText';
import EmptyStateNoSearch from '@components/EmptyState/EmptyStateNoSearch';
import { Scrollbar } from '@components/Hoverable/Scrollbar';
import { EditMessageHighlightModal } from '@components/Modals/EditMessageHighlightModal.web';
import { NotPaidConfirmModal } from '@components/Modals/NotPaidConfirmModal';
import { Box, Text } from '@components/Restyle';
import ActivityIndicatorLoading from '@components/shared/ActivityIndicatorLoading';
import {
  Document,
  Message,
  Scalars,
  useGetChatQuery,
} from '@graphql/generated';
import useActiveChat from '@hooks/useActiveChat';
import useChatInput from '@hooks/useChatInput';
import useEmoji from '@hooks/useEmoji';
import useMe from '@hooks/useMe';
import { usePreviousValue } from '@hooks/usePreviousValue';

type ChatMessageListProps = {
  list: Message[];
  filterVal?: string;
  fetchBeforeCursor: (cursor: string) => void;
  fetchAfterCursor: (cursor: string) => void;
  loading: boolean;
  refreshing: boolean;
  onRefresh: () => void;
  onPress?: (message: Message) => void;
  onLongPress?: (message: Message) => void;
  onEditTagPress: () => void;
  onForwardPress: () => void;
  onDeletedPress: () => void;
  scrollToCursor?: string;
  highlightedToGrey01MessageId?: string;
  onViewableMsgItemsChanged?: (viewItem: ViewToken[]) => void;
  showNewMessageIndicator: (newMsgId: string) => void;
  firstNewMsgId?: string;
  isArchived?: boolean;
  hasNextPage?: boolean;
};
export type ChatMessageListItem = Message | string;

type CompareItem = {
  id: string;
  clientId: string;
  hideSenderInfo: boolean;
  showOnlyTime: boolean;
};

// NOTE: FlashList strongly recommends setting the estimatedItemSize prop and
// recommends a value of 71 for the message list, for items that are purely text.
const ESTIMATED_ITEM_SIZE = 71;
const GROUPING_MESSAGE_TIME = 30 * 60 * 1000;

const ChatMessageList = forwardRef<
  FlashList<ChatMessageListItem>,
  ChatMessageListProps
>(
  (
    {
      list,
      filterVal = '',
      fetchBeforeCursor,
      fetchAfterCursor,
      loading,
      refreshing,
      onPress,
      onLongPress,
      onEditTagPress,
      onForwardPress,
      onDeletedPress,
      scrollToCursor = '',
      highlightedToGrey01MessageId = '',
      onViewableMsgItemsChanged,
      showNewMessageIndicator,
      firstNewMsgId = '',
      isArchived = false,
      hasNextPage = true,
    }: ChatMessageListProps,
    ref: React.ForwardedRef<FlashList<ChatMessageListItem>>
  ): JSX.Element => {
    const { t } = useTranslation('format');

    const { isPaid, setPaid, me } = useMe();
    const [isPaidUser, setPaidUser] = useState<boolean>(!!isPaid);
    const [showNotPaidConfirmModal, setShowNotPaidConfirmModal] =
      useState<boolean>(false);
    const [compareMessageList, setCompareMessageList] =
      useState<CompareItem[]>();

    const { setSelectedMessage } = useChatInput();
    const [xPos, setXPos] = useState(0);
    const [yPos, setYPos] = useState(0);
    const [showMenu, setShowMenu] = useState(false);
    const fadeAnim = useState(new Animated.Value(0))[0];
    const mainRef = useRef<HTMLElement>(null);
    const [bodyWidth, setBodyWidth] = useState(0);
    const [bodyHeight, setBodyHeight] = useState(0);
    const { setEmojiValue } = useEmoji();
    const [atBottom, setAtBottom] = useState<boolean>(true);
    const [scrollPostionY, setScrollPostionY] = useState(0);
    const [highlightedToGrey01MessageId1, setHighlightedToGrey01MessageId1] =
      useState('');

    const handleClick = () => {
      setTimeout(() => {
        setShowMenu(false);
      }, 100);
    };

    const handleContextMenu = (e: any) => {
      e.preventDefault();
    };

    useEffect(() => {
      setHighlightedToGrey01MessageId1(highlightedToGrey01MessageId);
    }, [highlightedToGrey01MessageId]);

    useEffect(() => {
      const element = mainRef.current;
      if (element) {
        element.addEventListener('click', handleClick);
        element.addEventListener('contextmenu', handleContextMenu);
        return () => {
          element.removeEventListener('click', handleClick);
          element.removeEventListener('contextmenu', handleContextMenu);
        };
      }
    }, []);

    useEffect(() => {
      Animated.timing(fadeAnim, {
        toValue: showMenu ? 1 : 0,
        duration: 100,
        useNativeDriver: false,
      }).start();
    }, [showMenu, fadeAnim]);

    const onRightClick = useCallback(
      (message: Message, mouseX: number, mouseY: number) => {
        setShowMenu(false);
        setSelectedMessage(message);
        if (bodyHeight - mouseY < 240) {
          mouseY = mouseY - (message.authorId == me?.id ? 323 : 275);
        }
        const element = mainRef.current;
        if (element) {
          const { left, top } = element.getBoundingClientRect();
          mouseX = mouseX - left;
          mouseY = mouseY - top;
        }
        if (bodyWidth - mouseX < 270) {
          mouseX = mouseX - 281;
        }
        setXPos(mouseX);
        setYPos(mouseY);
        setTimeout(() => {
          setShowMenu(true);
        }, 100);
      },
      [bodyWidth, bodyHeight]
    );

    const showNotPaidModal = (file: Document) => {
      const createdAt = dayjs(file.createdAt);
      const expired = createdAt?.diff(new Date(), 'day');
      if (!isPaidUser && expired < -1000) {
        setShowNotPaidConfirmModal(true);
        return true;
      }
      return false;
    };

    const [scrollNeeded, setScrollNeeded] = useState(!!scrollToCursor);
    const {
      isScrolling,
      displayMessageList,
      setDisplayMessageList,
      activeChat,
    } = useActiveChat();
    const { id: activeChatId } = activeChat || {};

    const createInfoStr = 'createdDateInfo';
    const derivedChatId = activeChat?.id ?? '';
    const { data: chatData } = useGetChatQuery({
      fetchPolicy: 'cache-and-network',
      variables: { id: derivedChatId },
      skip: !derivedChatId,
    });
    const { getChat: chat } = chatData || {};
    const { isGroupChat, users, owner, createdAt } = chat || {};

    const dateSort = (): Record<string, Message[]> => {
      const sortedByDate = list.sort((a, b) => +b.createdAt - +a.createdAt);

      return sortedByDate.reduce((acc: Record<string, Message[]>, obj) => {
        const { createdAt: date } = obj;
        const dateAsKey = t('timeAgoMessageList', {
          val: date,
        });
        const existingValue = acc[dateAsKey];

        return {
          ...acc,
          [dateAsKey]: [...(existingValue ? existingValue : []), obj],
        };
      }, {});
    };

    const messageList = Object.entries(dateSort()).reduce<
      ChatMessageListItem[]
    >((acc: ChatMessageListItem[], item) => {
      return [...acc, ...item[1], item[0]];
    }, []);

    if (!hasNextPage && isGroupChat) {
      const chatCreateStr = t('timeAgoMessageList', {
        val: createdAt,
      });
      if (messageList[messageList.length - 1] === chatCreateStr) {
        messageList.pop();
      }

      messageList.push(createInfoStr);
      messageList.push(chatCreateStr);
    }

    const ref_flashlist = useRef<FlashList<ChatMessageListItem>>(null);

    useImperativeHandle(
      ref,
      () => ref_flashlist.current as FlashList<ChatMessageListItem>
    );

    // Ensure we don't pass a negative number to the scrollview
    const messageIndexByCursor = Math.max(
      messageList.findIndex((item) => {
        if (typeof item === 'string') {
          return false;
        }
        return item.cursor === scrollToCursor;
      }),
      0
    );

    const previousFirstMessage = usePreviousValue<Message | undefined>(
      messageList[0]
    );
    const previousMessageListCount = usePreviousValue<number | undefined>(
      messageList.length
    );

    useEffect(() => {
      if (firstNewMsgId == '') {
        setDisplayMessageList(messageList);
      }
    }, [firstNewMsgId]);

    const debouncedSetDisplayMessageList = useCallback(
      debounce(() => {
        setDisplayMessageList(messageList);
      }, 100),
      [messageList]
    );

    useEffect(() => {
      const firstMessage = messageList[0];
      let isShow = false;
      if (
        firstMessage &&
        previousFirstMessage &&
        previousMessageListCount &&
        firstMessage?.id !== previousFirstMessage?.id
      ) {
        if (atBottom || (firstMessage?.isSender ?? false)) {
          ref_flashlist?.current?.scrollToIndex({
            index: 0,
            animated: false,
          });

          rendorCompareMessageList(true);
        } else {
          if (scrollPostionY > 50) {
            isShow = true;
            showNewMessageIndicator &&
              showNewMessageIndicator(firstMessage?.id);
          }

          isShow = scrollPostionY > 50;

          scrollPostionY > 50 &&
            showNewMessageIndicator &&
            showNewMessageIndicator(firstMessage?.id);
        }
      }

      if (firstNewMsgId == '' && !isShow) {
        if (messageList.length == displayMessageList.length) {
          debouncedSetDisplayMessageList();
        } else {
          setDisplayMessageList(messageList);
        }
      }
    }, [messageList]);

    useEffect(() => {
      if (messageList.length > 0) {
        rendorCompareMessageList(true);
      }
    }, [messageList.length]);

    const wheelEvent = () => {
      setScrollNeeded(false);
      setShowMenu(false);
    };

    useEffect(() => {
      window?.document.addEventListener('wheel', wheelEvent);
      return () => {
        window?.document.removeEventListener('wheel', wheelEvent);
      };
    }, [scrollNeeded]);

    useEffect(() => {
      setScrollNeeded(true);
      setShowMenu(false);
      setScrollPostionY(0);
    }, [activeChatId]);

    const scrollToMessage = (destinationIndex: number, animate?: boolean) => {
      ref_flashlist?.current?.scrollToIndex({
        index: destinationIndex,
        animated: animate || false,
      });
    };

    const rendorCompareMessageList = (renderYn: boolean) => {
      const newList = [...messageList].reverse();
      const compareList = [];
      let groupAuthorId = '';
      let groupFirstMsgTime: Scalars['ISO8601DateTime'];

      for (let index = 0; index < newList.length; index++) {
        const item = newList[index];
        if (typeof item !== 'string') {
          let hideSenderInfo = false;
          let showOnlyTime = false;
          if (groupFirstMsgTime == undefined) {
            groupAuthorId = item.authorId;
            groupFirstMsgTime = item.publishedAt
              ? item.publishedAt
              : item.createdAt;
            hideSenderInfo = false;
            showOnlyTime = false;
          } else {
            if (item.authorId === groupAuthorId) {
              hideSenderInfo = true;
              showOnlyTime = false;
              if (
                new Date(
                  item.publishedAt ? item.publishedAt : item.createdAt
                ).getTime() -
                  new Date(groupFirstMsgTime).getTime() >
                GROUPING_MESSAGE_TIME
              ) {
                groupFirstMsgTime = item.publishedAt
                  ? item.publishedAt
                  : item.createdAt;
                showOnlyTime = true;
              }
            } else {
              groupAuthorId = item.authorId;
              groupFirstMsgTime = item.publishedAt
                ? item.publishedAt
                : item.createdAt;
              hideSenderInfo = false;
              showOnlyTime = false;
            }
          }

          compareList.push({
            id: item.id,
            clientId: item.clientId,
            hideSenderInfo: hideSenderInfo,
            showOnlyTime: showOnlyTime,
          });
        } else {
          groupFirstMsgTime = undefined;
        }
      }
      if (renderYn) {
        setCompareMessageList(compareList);
      }

      return compareList;
    };

    const groupCreatedInfo = () => {
      const ownerName = owner?.name ?? '';
      const chatName = chat?.name ?? '';
      const searchWords = [ownerName, chatName];
      return (
        <Box alignItems='center' marginBottom='s'>
          <ChatSearchText
            textToHighlight={` ${ownerName} has created a group “${chatName}”`}
            searchWords={searchWords}
          />
          {users &&
            users
              .filter((user) => owner && user.id !== owner.id)
              .map((user) => (
                <ChatSearchText
                  textToHighlight={` ${ownerName} has added ${
                    user?.name ?? ''
                  }`}
                  key={user.id}
                  searchWords={searchWords}
                />
              ))}
        </Box>
      );
    };
    const renderFlashList = (
      showsVerticalScrollIndicator: boolean,
      setContentOffset?: (offset: { x: number; y: number }) => void,
      setContentSize?: (size: number) => void
    ) => {
      return (
        <FlashList
          onContentSizeChange={(_, height) => {
            setContentSize && setContentSize(height);
            if (scrollNeeded) {
              !isScrolling && scrollToMessage(messageIndexByCursor);
            }
          }}
          onScroll={({ nativeEvent: { contentOffset } }) => {
            setContentOffset && setContentOffset(contentOffset);
            setScrollPostionY(contentOffset.y);
            if (!loading && contentOffset.y <= 0) {
              const messages = displayMessageList?.filter(
                (m) => typeof m !== 'string'
              ) as Message[];
              const firstMessage = messages[0];
              const firstCursor = firstMessage?.cursor;

              fetchBeforeCursor(firstCursor);
            }
          }}
          onScrollBeginDrag={() => setScrollNeeded(false)}
          scrollEventThrottle={16}
          showsVerticalScrollIndicator={showsVerticalScrollIndicator}
          keyboardShouldPersistTaps='always'
          initialScrollIndex={messageIndexByCursor}
          onScrollToIndexFailed={(info) => {
            if (isScrolling) return;

            const wait = new Promise((resolve) => setTimeout(resolve, 500));

            wait.then(() => {
              scrollToMessage(info.index);
            });
          }}
          ref={ref_flashlist}
          inverted
          data={displayMessageList}
          keyExtractor={(item) =>
            typeof item === 'string' ? item.toString() : item.clientId
          }
          extraData={
            displayMessageList?.filter((m) => (m as Message).failedAt !== null)
              .length
          }
          onEndReachedThreshold={0.5}
          onEndReached={() => {
            const messages = displayMessageList?.filter(
              (m) => typeof m !== 'string'
            ) as Message[];
            const lastMessage = messages[messages.length - 1];
            const lastCursor = lastMessage?.cursor;
            fetchAfterCursor(lastCursor);
          }}
          getItemType={(item: ChatMessageListItem) => {
            // To achieve better performance, specify the type based on the item
            return typeof item === 'string' ? 'sectionHeader' : 'row';
          }}
          estimatedItemSize={ESTIMATED_ITEM_SIZE}
          ListHeaderComponent={() => <Box marginTop='m' />}
          ListFooterComponent={() => <Box marginBottom='m' />}
          viewabilityConfig={{
            itemVisiblePercentThreshold: 80,
          }}
          onViewableItemsChanged={({ viewableItems }) => {
            if (!displayMessageList) return;
            const firstMessage = displayMessageList[0];

            let hasFirst = false;
            viewableItems.forEach((t) => {
              if (t.item.id === (firstMessage?.id ?? 0)) {
                hasFirst = true;
              }
            });
            setAtBottom(hasFirst);
            onViewableMsgItemsChanged &&
              onViewableMsgItemsChanged(viewableItems);
          }}
          renderItem={({ item, index }) => {
            if (typeof item === 'string') {
              if (item === createInfoStr) {
                return groupCreatedInfo();
              } else {
                return (
                  <Box
                    alignItems='center'
                    marginBottom='s'
                    marginTop='s'
                    flexDirection='row'>
                    <Box
                      borderBottomColor='grey02'
                      borderBottomWidth={1}
                      flex={1}
                      ml='m'></Box>
                    <Text variant='metadata' mx='m' color='grey03'>
                      {item}
                    </Text>
                    <Box
                      borderBottomColor='grey02'
                      borderBottomWidth={1}
                      flex={1}
                      mr='m'></Box>
                  </Box>
                );
              }
            }

            const comparisonData = compareMessageList?.find(
              (c) => c.id === item.id || c.clientId === item.clientId
            );
            let hideSenderInfo = comparisonData?.hideSenderInfo || false;
            let showOnlyTime = comparisonData?.showOnlyTime || false;
            if (index == 0 && item.isDraft && comparisonData == undefined) {
              const list = rendorCompareMessageList(false);
              const comparisonData = list?.find(
                (c) => c.id === item.id || c.clientId === item.clientId
              );
              hideSenderInfo = comparisonData?.hideSenderInfo || false;
              showOnlyTime = comparisonData?.showOnlyTime || false;
            }

            return (
              <Box marginTop={!hideSenderInfo ? 'xs' : undefined}>
                <ChatMessage
                  message={item}
                  chatData={chatData}
                  filterVal={filterVal}
                  onPress={() => onPress && onPress(item)}
                  onLongPress={() => onLongPress && onLongPress(item)}
                  onEditTagPress={() => {
                    onEditTagPress();
                  }}
                  onForwardPress={() => onForwardPress()}
                  highlighted={item.cursor == scrollToCursor}
                  highlightedToGrey01={
                    item.id === highlightedToGrey01MessageId1
                  }
                  showNotPaidModal={(file: Document) =>
                    showNotPaidModal && showNotPaidModal(file)
                  }
                  hideSenderInfo={hideSenderInfo}
                  showOnlyTime={showOnlyTime}
                  onRightClick={onRightClick}
                />
              </Box>
            );
          }}
        />
      );
    };

    return (
      <Box
        flex={1}
        ref={mainRef}
        onLayout={(e: any) => {
          if (e.nativeEvent.layout.width > 0) {
            setBodyWidth(e.nativeEvent.layout.width);
            setBodyHeight(e.nativeEvent.layout.height);
          }
        }}>
        <Scrollbar
          inverted
          HoverableProps={{
            style: { flex: 1, cursor: 'default' },
          }}>
          {({ setContentOffset, setContentSize, scrollTo }) => {
            scrollTo !== undefined &&
              ref_flashlist.current?.scrollToOffset({ offset: scrollTo });
            return (
              <>
                {displayMessageList?.length === 0 &&
                  filterVal?.length > 0 &&
                  !loading && <EmptyStateNoSearch />}
                {renderFlashList(false, setContentOffset, setContentSize)}
                {loading && !refreshing && <ActivityIndicatorLoading />}
              </>
            );
          }}
        </Scrollbar>
        {showMenu && (
          <Animated.View
            style={{
              width: 281,
              position: 'absolute',
              opacity: fadeAnim,
              top: yPos,
              left: xPos,
            }}>
            <EditMessageHighlightModal
              onEditTagPress={onEditTagPress}
              onForwardPress={onForwardPress}
              onDeletePress={onDeletedPress}
              onEmoji={setEmojiValue}
              isArchived={isArchived}
            />
          </Animated.View>
        )}
        {showNotPaidConfirmModal && (
          <NotPaidConfirmModal
            showModal={showNotPaidConfirmModal}
            onClose={() => setShowNotPaidConfirmModal(false)}
            onPress={() => {
              setShowNotPaidConfirmModal(false);
              setPaid(true);
              setPaidUser(true);
            }}
            title='Chat'
          />
        )}
      </Box>
    );
  }
);

export default ChatMessageList;
