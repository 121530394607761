import ExpoCachedImage from 'expo-cached-image';
import * as Device from 'expo-device';
import React, { useEffect, useState } from 'react';
import {
  Platform,
  Image,
  ImageResizeMode,
  StyleProp,
  ImageStyle,
} from 'react-native';

import { Box } from '@components/Restyle';
import { File, LocalFile } from '@graphql/generated';
import theme from '@themes/theme';
import { generateThumbnail } from '@utils/thumbnailUtil';

type CachedImagePropsType = {
  image: File | LocalFile;
  width?: number;
  screenWidth?: number;
  height?: number;
  marginRight?: number;
  isRemoveable?: boolean;
  croppingStrategy?: 'c-at_max_enlarge';
  resizeMode?: ImageResizeMode;
  borderRadius?: number;
  skipCache?: boolean;
  style?: Omit<StyleProp<ImageStyle>, 'width' | 'height' | 'opacity'>;
  placeholderContentFlex?: 0 | 1;
  from?: string;
  contentType?: string | null;
};

const CachedImage: React.FC<CachedImagePropsType> = ({
  image,
  width,
  screenWidth,
  height,
  isRemoveable,
  borderRadius,
  resizeMode,
  croppingStrategy,
  skipCache = true,
  style: styleProp,
  placeholderContentFlex = 1,
  from = '',
  contentType,
}) => {
  const [thumbnailUri, setThumbnailUri] = useState<string | null>(null);
  const CachedImageForPlatform =
    Platform.OS === 'web' || skipCache ? Image : ExpoCachedImage;

  const isLocal =
    image.url.startsWith('file:') || image.url.startsWith('data:');

  const format = 'f-auto';

  // ImageKit device density options:
  // https://docs.imagekit.io/features/image-transformations/resize-crop-and-other-transformations#dpr-dpr
  const density = Device.isDevice ? 'dpr-2' : 'dpr-1';

  // ImageKit image quality options:
  // https://docs.imagekit.io/features/image-transformations/resize-crop-and-other-transformations#quality-q
  // Note that a density setting of 2 is recommended to have a lower quality.

  let quality;

  if (from === 'search') {
    quality = Device.isDevice ? 'q-40' : 'q-60';
  } else {
    quality = Device.isDevice ? 'q-60' : 'q-80';
  }

  const transformations = ['/tr:', format, density, quality];

  if (width) transformations.push(`w-${width}`);
  if (height) transformations.push(`h-${height}`);
  if (croppingStrategy) transformations.push(croppingStrategy);

  const getPlaceHolderBackGroundColor = () => {
    if (contentType?.includes('video')) return theme.colors.black;
    else if (Platform.OS === 'web') return theme.colors.grey02;
    return theme.colors.white;
  };

  const style: StyleProp<ImageStyle> = [
    {
      ...styleProp,
      width: screenWidth ? screenWidth : width,
      height: height || '100%',
      opacity: isLocal && !isRemoveable ? 0.5 : 1.0,
      backgroundColor: getPlaceHolderBackGroundColor(),
      borderRadius: borderRadius || undefined,
    },
  ];

  const generateThumbnailForImage = async () => {
    try {
      if (contentType?.includes('video')) {
        const thumbnail = await generateThumbnail(
          image.url,
          width || 300,
          height || 200
        );
        setThumbnailUri(thumbnail);
      }
    } catch (error) {
      console.error('Error generating thumbnail:', error);
    }
  };

  useEffect(() => {
    if (contentType?.includes('video')) {
      generateThumbnailForImage();
    }
  }, [image.url, contentType]);
  const renderImage = () => {
    if (isLocal) {
      return (
        <Image
          source={{ uri: image.url }}
          style={style}
          resizeMode={resizeMode || 'cover'}
        />
      );
    }

    const transformationList = transformations.join(',');
    const cdnUrl = `${image.cdnBaseUrl}${transformationList}${image.path}`;
    const cacheKey = `${transformationList}${image.path}`.replace(/\W+/gi, '_');

    return (
      <CachedImageForPlatform
        source={{
          uri: thumbnailUri ?? cdnUrl,
          expiresIn: undefined, // never expire
        }}
        cacheKey={cacheKey}
        style={style}
        resizeMode={resizeMode || 'cover'}
        placeholderContent={
          <Box
            backgroundColor='grey02'
            style={{
              flex: placeholderContentFlex,
              height,
              width,
              borderRadius: borderRadius || undefined,
            }}
          />
        }
      />
    );
  };

  return renderImage();
};

export default CachedImage;
