import React from 'react';

import ContactFormStack from '@components/Contacts/ContactFormStack.web';
import { useGetGroupQuery } from '@graphql/generated';

type EditContactGroupProps = {
  contactGroupId?: string;
};
export const EditContactGroup: React.FC<EditContactGroupProps> = ({
  contactGroupId,
}) => {
  const { data } = useGetGroupQuery({
    variables: { id: contactGroupId },
  });

  const { id, name, currentUserTeamRole, team, users } = data?.getGroup ?? {
    name: '',
  };

  const initialValues = {
    id: id,
    name: name,
    teamId: team?.id || '',
    users: users ?? [],
  };

  return (
    <ContactFormStack
      screenName='EditGroupStack'
      initialValues={initialValues}
      currentUserTeamRole={currentUserTeamRole ?? ''}
    />
  );
};
