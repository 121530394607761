import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, Animated, TouchableOpacity } from 'react-native';

import { Box } from '@components/Restyle';
import Text from '@components/Restyle/Text';
import Icon from '@components/shared/Icon/Icon';
import theme from '@themes/theme';

type TaskCreatedToastProps = {
  taskName: string;
  duration: number;
  onPress?: () => void;
  message?: string;
  isFromProject?: boolean;
};

export const TaskCreatedToast: React.FC<TaskCreatedToastProps> = ({
  taskName,
  duration,
  onPress,
  message,
  isFromProject = false,
}) => {
  const progressAnim = useRef(new Animated.Value(0)).current;
  const { t } = useTranslation();
  useEffect(() => {
    Animated.timing(progressAnim, {
      toValue: 1,
      duration: duration,
      useNativeDriver: false,
    }).start();
  }, [progressAnim, duration]);

  const handlePress = () => {
    onPress && onPress();
  };

  return (
    <TouchableOpacity
      activeOpacity={0.8}
      onPress={handlePress}
      accessibilityLabel={
        isFromProject
          ? t('models:customToast.projectCreated')
          : t('models:tasks.create.taskCreated')
      }
      style={styles.toastContainer}>
      <Box style={styles.iconContainer}>
        <Icon name='Selected' height={16} width={16} color='white' />
      </Box>
      <Box style={styles.textContainer}>
        <Text variant='labelEmphasized' style={styles.titleText}>
          {message}
        </Text>
        <Text variant='labelSmall' style={styles.taskNameText}>
          {taskName}
        </Text>
      </Box>
      <Box style={styles.arrowIcon}>
        <Icon name='ArrowRight' height={24} width={24} color='white' />
      </Box>
      <Animated.View
        style={[
          styles.progressBar,
          {
            width: progressAnim.interpolate({
              inputRange: [0, 1],
              outputRange: ['0%', '100%'],
            }),
          },
        ]}
      />
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  toastContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: 'black',
    paddingVertical: theme.spacing.xs,
    paddingHorizontal: theme.spacing.xxs,
    borderRadius: theme.borderRadii.s,
    position: 'relative',
  },
  iconContainer: {
    marginHorizontal: theme.spacing.s,
  },
  arrowIcon: {
    justifyContent: 'center',
    alignItems: 'center',
    marginHorizontal: theme.spacing.s,
  },
  textContainer: {
    flex: 1,
    justifyContent: 'center',
  },
  titleText: {
    color: 'white',
    fontWeight: 'bold',
  },
  taskNameText: {
    color: 'white',
  },
  progressBar: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    height: 2,
    backgroundColor: '#00cc66', // Progress bar color
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },
});
