/* eslint-disable import/no-default-export */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ParamListBase,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import * as Sentry from '@sentry/react-native';
import { Field, FormikErrors, Formik } from 'formik';
import jwt_decode from 'jwt-decode';
import React, { useEffect, useState } from 'react';
import AppleSignin from 'react-apple-signin-auth';
import { useTranslation } from 'react-i18next';
import { ActivityIndicator, TouchableOpacity } from 'react-native';

import { Alert } from '@components/Alert/index.web';
import { ConfirmModal } from '@components/Modals/ConfirmModal';
import { Box, Text } from '@components/Restyle';
import Button from '@components/shared/Button/Button';
import Icon from '@components/shared/Icon/Icon';
import TextField from '@components/shared/TextField/TextField';
import { CheckBoxButton } from '@components/Web/CheckBoxButton';
import { DeviceToken } from '@graphql/generated';
import { useGoogleAuth } from '@hooks/useGoogleAuth';
import { AuthFormValues } from '@navigation/auth/log-in-stack';
import authSchema from '@schemas/authSchema';
import { useAuthContext } from '@src/context/authContext';
import { logEvent } from '@utils/eventLogger';
import { getApiConfig } from '@utils/getApiConfig';
import { getDeviceId } from '@utils/getDeviceIdUtil';
import { AsyncStorage } from '@utils/storage';

type UserInfo = {
  id: string;
  email: string;
  given_name: string;
  family_name: string;
  provider?: string;
};

export const EmailLogin: React.FC<ParamListBase> = () => {
  const navigation = useNavigation();
  const [loading, setLoading] = useState<boolean>(false);
  const { prompt, userInfo, setUserDetail } = useGoogleAuth();
  const apiConfig = getApiConfig();
  const { setAuthToken } = useAuthContext();
  const { t } = useTranslation('models');
  const [showProviderModal, setShowProviderModal] = useState(false);
  const [errorName, setErrorName] = useState('');

  const [keepsigned, setKeepsigned] = useState(true);
  const [resetLoadingState, setResetLoadingState] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const [forgotPasswordError, setForgotPasswordError] = useState('');

  const route = useRoute();
  const fromReset = route?.params?.fromReset ?? false;

  const [showMessage, setShowMessage] = useState(fromReset);

  const initialValues: AuthFormValues = {
    email: '',
    password: '',
  };

  useEffect(() => {
    userInfo && setLoading(true);
    userInfo && verifyUserEmail(userInfo);
  }, [userInfo]);

  const verifyUserEmail = async (userInfo: UserInfo) => {
    const verifyUser = `/api/v3/verify_user.json`;
    const userPayload = {
      user: {
        email: userInfo?.email?.toLowerCase(),
        provider: userInfo?.provider,
        uid: userInfo?.id,
        is_verified: false,
        device_token: await getDeviceId(),
        token_type: DeviceToken.Firebase,
      },
    };
    const authEndpoint = `${apiConfig.apiUrl}${verifyUser}`;
    await fetch(authEndpoint, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(userPayload),
    })
      .then((response) => response.json())
      .then(async (response) => {
        setLoading(false);
        if (response?.error) {
          if (response.auth_provider) {
            setShowProviderModal(true);
            setErrorName(response.error);
          } else {
            navigation.navigate('register', {
              socialSignUp: true,
            });
          }
        } else if (response.user) {
          try {
            window.sessionStorage.setItem('invitation_token', '');
            await AsyncStorage.setItem(
              'authToken',
              response.user.api_tokens[0].token
            );
          } catch (e) {
            // console.log("catch error::",e);
          } finally {
            Sentry.setUser({
              email: userPayload.user.email,
              username: userPayload.user.uid,
            });
            setAuthToken(response.user.api_tokens[0].token);
          }
        }
      })
      .catch((_err) => {
        // console.log('err', err);
      });
  };

  const appleSignInWeb = async (response: any) => {
    try {
      const localResponse = await AsyncStorage.getItem('appleUserInfo');
      let decodeEmail = '';

      if (!localResponse) {
        const token = response.authorization.id_token;
        const decoded = jwt_decode(token);
        if (decoded) {
          decodeEmail = decoded.email;
        }
      }
      const userData = JSON.parse(localResponse);
      const userInfoPayload = {
        id:
          response.authorization.id_token || userData?.authorization?.id_token,
        email: response?.user?.email || userData?.user?.email || decodeEmail,
        given_name:
          response?.user?.name?.firstName || userData?.user?.name?.firstName,
        family_name:
          response?.user?.name?.lastName || userData?.user?.name?.lastName,
        provider: 'Apple',
      };
      if (response.user && response.user.email) {
        await AsyncStorage.setItem('appleUserInfo', JSON.stringify(response));
        setUserDetail && setUserDetail(userInfoPayload);
      } else if (response) {
        setUserDetail && setUserDetail(userInfoPayload);
      } else {
        Alert.alert('TaskTag', t('onBoarding.error.emailNotFound'));
      }
    } catch (e) {
      // console.log('Apple Sign In Error:-', e);
    }
  };

  useEffect(() => {
    setTimeout(() => setShowMessage(false), 5000);
  }, []);

  const logIn = async (
    email: string,
    password: string,
    setErrors: (errors: FormikErrors<typeof initialValues>) => void
  ) => {
    await AsyncStorage.setItem('keepSignedIn', keepsigned ? 'true' : 'false');
    window.sessionStorage.setItem('setKeepSignedIn', '1');

    const login = `/api/v3/auth.json`;
    const loginPayload = {
      email: email.toLowerCase(),
      password: password,
      device_token: await getDeviceId(),
      token_type: DeviceToken.Firebase.toLowerCase(),
    };

    const authEndpoint = `${apiConfig.apiUrl}${login}`;

    await fetch(authEndpoint, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(loginPayload),
    })
      .then((response) => response.json())
      .then(async (response) => {
        if (response?.error) {
          setErrors({
            password: response.error,
          });
        } else {
          try {
            await AsyncStorage.setItem('authToken', response.token);

            logEvent({ name: 'login', params: { email } });
          } catch (e) {
            // saving error
            console.log(e);
          } finally {
            Sentry.setUser({ email: email, username: email });
            setAuthToken(response.token);
          }
        }
      })
      .catch((err) => {
        console.log('err', err);
        setErrors({
          password: 'network error',
        });
      });
  };

  return (
    <Formik
      validateOnMount={true}
      initialValues={initialValues}
      onSubmit={(values, { setErrors }) =>
        logIn(values.email, values.password, setErrors)
      }
      validationSchema={authSchema}>
      {({
        submitForm,
        values,
        isValid,
        validateField,
        errors,
        setFieldTouched,
      }) => {
        useEffect(() => {
          setForgotPasswordError('');
        }, [values.email]);
        const forgotPassword = async () => {
          const resetEndpoint = `${apiConfig.apiUrl}/api/v1/password.json`;

          setResetLoadingState(true);

          await fetch(resetEndpoint, {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              user: {
                email: values.email.toLowerCase(),
              },
            }),
          })
            .then((response) => response.json())
            .then(async (response) => {
              if (!response.success) {
                setForgotPasswordError(
                  'Error happened, please revise the email address.'
                );
              } else {
                navigation.navigate('forgot-password', { email: values.email });
              }
            })
            .catch((err) => console.log('err===', err))
            .finally(() => setResetLoadingState(false));
        };
        return (
          <Box backgroundColor='grey01' height='100%'>
            <Box alignItems='center' justifyContent='center' height='100%'>
              <Box
                alignItems='center'
                borderRadius='m'
                backgroundColor='white'
                style={{
                  paddingVertical: 32,
                  paddingHorizontal: 40,
                  shadowColor: 'black',
                  shadowOpacity: 0.05,
                  shadowOffset: { width: 0, height: 25 },
                  shadowRadius: 25,
                  position: 'absolute',
                  width: 492,
                  overflow: 'hidden',
                }}>
                <Icon name='Logo' width={142} height={43} />
                <Text
                  style={{ marginTop: 42, marginBottom: 26 }}
                  variant='heading2'
                  color='black'>
                  {t('onBoarding.modal.welcomeback')}
                </Text>

                <Box flex={1}>
                  <Box marginBottom='l'>
                    <Text
                      variant='labelSmall'
                      marginBottom='xs'
                      style={{ lineHeight: 16 }}
                      color='textPrimary'>
                      {t('shared:email')}
                    </Text>
                    <Field
                      component={TextField}
                      placeholder='example@site.com'
                      placeholderTextColor='grey04'
                      marginBottom='none'
                      name='email'
                      textInputProps={{
                        autoCapitalize: 'none',
                        autoCorrect: false,
                        keyboardType: 'email-address',
                      }}
                      accessibilityLabel={t('shared:email')}
                      validateOnChange={false}
                      onKeyPress={({ nativeEvent: { key } }) => {
                        if (key === 'Enter' && isValid) submitForm();
                      }}
                    />
                    {forgotPasswordError != '' && (
                      <Box
                        flexDirection='row'
                        alignItems='center'
                        marginTop='xs'
                        marginRight='m'>
                        <Icon
                          name='AlertTriangle'
                          color='alertRed'
                          variant='s'
                          marginRight='xxs'
                        />
                        <Text variant='error'>{forgotPasswordError}</Text>
                      </Box>
                    )}
                  </Box>
                  <Box flexDirection='row' marginBottom='xs'>
                    <Text
                      variant='labelSmall'
                      style={{ lineHeight: 16 }}
                      color='textPrimary'>
                      {t('shared:password')}
                    </Text>
                    <Box flex={1}></Box>
                    <TouchableOpacity
                      onPress={async () => {
                        setFieldTouched('email', true);
                        validateField('email');
                        if (!errors.email) forgotPassword();
                      }}
                      disabled={resetLoadingState}
                      accessibilityLabel={t('shared:forgotPassword')}>
                      <Text variant='webBodySecondary' color='greenSecondary'>
                        {t('shared:forgotPassword')}
                      </Text>
                    </TouchableOpacity>
                    {resetLoadingState && (
                      <Box marginLeft='s'>
                        <ActivityIndicator style={{ height: 16 }} />
                      </Box>
                    )}
                  </Box>
                  <Field
                    component={TextField}
                    placeholder='Enter password'
                    placeholderTextColor='grey04'
                    name='password'
                    isLarge
                    suffix={
                      <Icon
                        accessibilityLabel={
                          showPassword ? 'Hide password' : 'Show password'
                        }
                        name={showPassword ? 'Eye' : 'EyeOff'}
                        variant='l'
                        color='textPrimary'
                        onPress={() => setShowPassword(!showPassword)}
                      />
                    }
                    textInputProps={{
                      autoCapitalize: 'none',
                      autoCorrect: false,
                      secureTextEntry: !showPassword,
                    }}
                    accessibilityLabel={t('shared:password')}
                    validateOnChange={true}
                    onKeyPress={({ nativeEvent: { key } }) => {
                      if (key === 'Enter' && isValid) submitForm();
                    }}
                  />
                  <TouchableOpacity
                    onPress={() => {
                      setKeepsigned(!keepsigned);
                    }}>
                    <Box
                      accessibilityLabel={t('onBoarding.modal.keepMeSignedIn')}
                      flexDirection='row'
                      alignItems='center'
                      justifyContent='flex-start'
                      mb='l'>
                      <CheckBoxButton checked={keepsigned} />
                      <Text
                        marginLeft='xs'
                        variant='typoMetadata'
                        color='textPrimary'>
                        {t('onBoarding.modal.keepMeSignedIn')}
                      </Text>
                    </Box>
                  </TouchableOpacity>
                  <Button
                    onPress={async () => {
                      submitForm();
                    }}
                    marginBottom='l'
                    fullWidth
                    isSmall={true}
                    height={48}
                    disabled={!isValid}
                    backgroundColor={isValid ? 'black' : 'grey02'}
                    accessibilityLabel={t('onBoarding.logIn')}>
                    <Text
                      variant='labelSmall'
                      color={isValid ? 'white' : 'grey04'}
                      style={{ lineHeight: 16 }}>
                      {t('onBoarding.logIn')}
                    </Text>
                  </Button>
                  <Box
                    flexDirection='row'
                    mb='l'
                    alignItems='center'
                    style={{
                      height: 16,
                    }}>
                    <Box
                      flex={1}
                      backgroundColor='grey04'
                      style={{
                        height: 1,
                        marginRight: 23,
                      }}></Box>

                    <Text variant='webBodySecondary' color='grey04'>
                      {t('onBoarding.modal.orLoginWith')}
                    </Text>
                    <Box
                      flex={1}
                      backgroundColor='grey04'
                      style={{
                        height: 1,
                        marginLeft: 23,
                      }}></Box>
                  </Box>

                  <Box flexDirection='row' mb='l'>
                    <Box mr='xs' width={202}>
                      <Button
                        onPress={() => {
                          prompt();
                        }}
                        prefix={
                          <Icon
                            variant='l'
                            name='Google'
                            color='textPrimary'
                            accessibilityLabel='googleIcon'
                          />
                        }
                        isSmall={true}
                        height={48}
                        fullWidth
                        prefixMarginRight='xs'
                        disabled={loading}
                        borderWidth={1}
                        borderColor='grey03'
                        backgroundColor='white'
                        accessibilityLabel={t('onBoarding.modal.google')}
                        alignTextLeft={false}>
                        <Text variant='webBodySecondary' color='black'>
                          {t('onBoarding.modal.google')}
                        </Text>
                      </Button>
                    </Box>
                    <Box width={202}>
                      <AppleSignin
                        /** Auth options passed to AppleID.auth.init()  */
                        authOptions={{
                          clientId: process.env.APPLE_CLIENT_ID_WEB,
                          scope: 'name email',
                          redirectURI: process.env.APPLE_REDIRECT_UI,
                          state: '',
                          nonce: 'nonce',
                          usePopup: true,
                        }}
                        render={(props: any) => (
                          <Button
                            fullWidth
                            isSmall={true}
                            height={48}
                            borderWidth={1}
                            borderColor='grey03'
                            backgroundColor='white'
                            alignTextLeft={false}
                            prefixMarginRight='xs'
                            accessibilityLabel={t('onBoarding.modal.apple')}
                            prefix={
                              <Icon
                                variant='l'
                                name='Apple'
                                color='textPrimary'
                                accessibilityLabel={t('onBoarding.modal.apple')}
                              />
                            }
                            {...props}>
                            <Text variant='webBodySecondary' color='black'>
                              {t('onBoarding.modal.apple')}
                            </Text>
                          </Button>
                        )}
                        uiType='light'
                        className='apple-auth-btn'
                        onSuccess={(response: any) => appleSignInWeb(response)} // default = undefined
                        // eslint-disable-next-line no-console
                        onError={(error: any) => console.error('error', error)} // default = undefined
                      />
                    </Box>
                  </Box>
                  <Box alignItems='center' my='xxxs'>
                    <Text variant='webBodySecondary' color='black'>
                      {t('onBoarding.modal.dontHaveAnAccount')}{' '}
                      <Text
                        accessibilityLabel={t('onBoarding.signUp')}
                        variant='webBodySecondary'
                        color='greenSecondary'
                        onPress={() => {
                          navigation.navigate('register');
                        }}>
                        {t('onBoarding.signUp')}
                      </Text>
                    </Text>
                  </Box>
                </Box>
              </Box>
              {showMessage && (
                <Box
                  backgroundColor='black'
                  borderRadius='xs'
                  px='s'
                  py='xs'
                  style={{
                    opacity: 0.8,
                    position: 'absolute',
                    bottom: 40,
                  }}>
                  <Text variant='webSmall' color='white'>
                    {t('models:onBoarding.modal.thePasswordHasBeenUpdated')}
                  </Text>
                </Box>
              )}
            </Box>
            {showProviderModal && (
              <ConfirmModal
                showModal={showProviderModal}
                onClose={() => setShowProviderModal(false)}
                onPress={() => setShowProviderModal(false)}
                buttonText={t('onBoarding.error.okPress')}
                title={t('onBoarding.error.title')}
                message={t(
                  'onBoarding.error.userAlreadyRegisteredWithProvider',
                  {
                    provider: errorName,
                  }
                )}
              />
            )}
          </Box>
        );
      }}
    </Formik>
  );
};
