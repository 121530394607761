import React, { createContext, useContext } from 'react';

import { FlipperFeature, useListFeatureFlagsQuery } from '@graphql/generated';
import { useAuthContext } from '@src/context/authContext';

type FeatureFlagContext = {
  listFeatureFlags: Array<FlipperFeature>;
};

const featureFlagContext = createContext<FeatureFlagContext | undefined>(
  undefined
);

export const FeatureFlagProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { Provider } = featureFlagContext;
  const { token } = useAuthContext();

  const { data } = useListFeatureFlagsQuery({ skip: !token });
  const { listFeatureFlags } = data || {};

  return <Provider value={{ listFeatureFlags }}>{children}</Provider>;
};

const useFeatureFlag = (): FeatureFlagContext => {
  const context = useContext(featureFlagContext);
  if (context === undefined) {
    throw new Error('useFeatureFlag must be used within a Provider');
  }
  return context;
};

export default useFeatureFlag;
