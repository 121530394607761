import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SectionList } from 'react-native';

import EmptyStateNoFiles from '@components/EmptyState/EmptyStateNoFiles';
import { NotPaidConfirmModal } from '@components/Modals/NotPaidConfirmModal';
import { Box, Text } from '@components/Restyle';
import ActivityIndicatorLoading from '@components/shared/ActivityIndicatorLoading';
import ImageGrid from '@components/shared/ImageGrid.web';
import { Document } from '@graphql/generated';
import { useListProjectDocumentsFromQuery } from '@hooks/useListProjectDocumentsFromQuery';
import useMe from '@hooks/useMe';
import { usePreviewDocuments } from '@hooks/usePreviewDocuments';
import { ImagesPreview } from '@screens/Chats/ImagesPreview.web';
import { ProjectFilesProps } from '@screens/Projects/ProjectFiles.web';
import { filterByDateRange } from '@utils/tasks';

export const ProjectMedia: React.FC<ProjectFilesProps> = ({
  projectId,
  ownerId,
  sortBy,
  isSelectMode,
  onSelectedModeChange,
  selectedList,
  handelSelectedItem,
  dateRange,
}) => {
  const { setPreviewDocuments, setActiveIndex } = usePreviewDocuments();
  const { t } = useTranslation('format');
  const [isImageCarouselVisible, setImageCarouselVisible] = useState(false);

  const { isPaid, setPaid } = useMe();
  const [isPaidUser, setPaidUser] = useState<boolean>(!!isPaid);
  const [showNotPaidConfirmModal, setShowNotPaidConfirmModal] =
    useState<boolean>(false);

  const showNotPaidModal = (file: Document) => {
    const createdAt = dayjs(file.createdAt);
    const expired = createdAt?.diff(new Date(), 'day');
    if (!isPaidUser && expired < -1000) {
      setShowNotPaidConfirmModal(true);
      return true;
    }
    return false;
  };

  const [loadingMore, setLoadingMore] = useState(false);
  const { documents, loading, refetch, pageInfo, fetchFromCursor } =
    useListProjectDocumentsFromQuery({
      projectIds: [projectId],
      ownerIds: ownerId ? [ownerId] : undefined,
      sortBy,
      includeMedia: true,
      first: 30,
      fetchPolicy: 'network-only',
      onFetchMoreCompleted: () => {
        setLoadingMore(false);
      },
    });

  useEffect(() => {
    refetch({
      first: 30,
      ownerIds: ownerId ? [ownerId] : undefined,
      projectIds: [projectId],
      sortBy,
      includeMedia: true,
    });
  }, [sortBy]);

  const renderFooterUI = (isLoadingMore: boolean) => {
    return (
      <Box marginBottom='listFooter'>
        {isLoadingMore && loadingMore && <ActivityIndicatorLoading />}
      </Box>
    );
  };

  const removeDuplicates = (docs: Document[]) => {
    return docs?.reduce((doc: Document[], current) => {
      if (!doc.find((item) => item.id === current.id)) {
        doc.push(current);
      }
      return doc;
    }, []);
  };

  const sectionData = () => {
    if (documents?.length === 0) {
      return [];
    }

    const filteredDocuments = dateRange
      ? filterByDateRange(documents, ({ createdAt }) => createdAt, dateRange)
      : documents;

    const docs = removeDuplicates(filteredDocuments);

    const list = docs?.reduce(
      (acc: Record<string, Document[]>, obj: Document) => {
        const { createdAt: date } = obj;
        const dateAsKey = t('monthAndDayFormatDash', {
          val: date,
        });
        const existingValue = acc[dateAsKey];

        return {
          ...acc,
          [dateAsKey]: [...(existingValue ? existingValue : []), obj],
        };
      },
      {}
    );

    if (list) {
      const documentList = Object.entries(list).map(([key, value]) => {
        return { title: key, data: [value] };
      });
      const sectionDocumentsByDate = documentList.sort(
        (a, b) => +new Date(b.title) - +new Date(a.title)
      );
      return sectionDocumentsByDate;
    } else {
      return [];
    }
  };

  const renderItem = ({ item }: { item: Document[] }) => {
    return (
      <Box marginHorizontal='m' marginBottom='xs' id={item[0].id}>
        <ImageGrid
          list={item}
          selectedList={selectedList}
          isSelectMode={isSelectMode}
          onPress={(image) => {
            if (showNotPaidModal(image as Document)) return;
            if (isSelectMode) {
              handelSelectedItem(image);
            } else {
              setPreviewDocuments(item);
              setActiveIndex(item.findIndex((i) => i.id === image.id));
              setImageCarouselVisible(true);
            }
          }}
          onLongPress={(image) => {
            onSelectedModeChange(true);
            handelSelectedItem(image);
          }}
        />
      </Box>
    );
  };

  if (loading) return <ActivityIndicatorLoading />;

  return (
    <Box flex={1}>
      <SectionList
        showsVerticalScrollIndicator={false}
        stickySectionHeadersEnabled={false}
        sections={sectionData()}
        renderItem={renderItem}
        ItemSeparatorComponent={() => <Box marginBottom='l' />}
        ListFooterComponent={() => renderFooterUI(pageInfo?.hasNextPage)}
        onEndReached={() => {
          fetchFromCursor();
        }}
        onEndReachedThreshold={0.5}
        ListHeaderComponent={() => <Box marginTop='xs' />}
        renderSectionHeader={({ section: { title } }) => (
          <Box marginTop='m' marginBottom='xs' marginHorizontal='m'>
            <Text variant='bodyTaskName' color='textPrimary'>
              {title}
            </Text>
          </Box>
        )}
        ListEmptyComponent={() => <EmptyStateNoFiles />}
        contentContainerStyle={{ flexGrow: 1 }}
      />
      {isImageCarouselVisible && (
        <ImagesPreview onDismiss={() => setImageCarouselVisible(false)} />
      )}
      {showNotPaidConfirmModal && (
        <NotPaidConfirmModal
          showModal={showNotPaidConfirmModal}
          onClose={() => setShowNotPaidConfirmModal(false)}
          onPress={() => {
            setShowNotPaidConfirmModal(false);
            setPaid(true);
            setPaidUser(true);
          }}
          title='Project'
        />
      )}
    </Box>
  );
};
