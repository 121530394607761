import { useSafeAreaInsets } from 'react-native-safe-area-context';

import theme from '@themes/theme';

import { Box } from '../Restyle';

interface HeaderWrapperProps {
  simple?: boolean;
  children: React.ReactNode;
  topWrap?: boolean;
}

export const HeaderWrapper: React.FC<HeaderWrapperProps> = ({
  children,
  simple,
  topWrap = true,
}) => {
  const insets = useSafeAreaInsets();
  const getPaddingTop = () => {
    if (!topWrap) {
      return theme.spacing.m;
    }
    if (simple) {
      return 'auto';
    }
    return insets.top;
  };

  return (
    <Box
      paddingHorizontal='m'
      paddingBottom='m'
      borderBottomColor='grey02'
      borderBottomWidth={1}
      flexDirection='row'
      alignItems='center'
      style={{ paddingTop: getPaddingTop() }}
      backgroundColor='white'>
      {children}
    </Box>
  );
};
