import { useNavigation } from '@react-navigation/native';
import { Field, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActivityIndicator, Alert, Dimensions } from 'react-native';

import GoogleReCaptcha from '@components/Modals/GoogleReCaptcha';
import { Box, ShadowBox, Text } from '@components/Restyle';
import Button from '@components/shared/Button/Button';
import Icon from '@components/shared/Icon/Icon';
import PhoneField from '@components/shared/PhoneField/PhoneField';
import TextField from '@components/shared/TextField/TextField';
import { PopupTimeZoneButtonWeb } from '@components/Web/PopupTimeZoneButton.web';
import { useGoogleAuth } from '@hooks/useGoogleAuth';
import { RegisterStackScreenProps } from '@navigation/auth/register-stack-new.web';
import { RegisterFormValues } from '@screens/Auth/RegisterForm.web';
import theme from '@themes/theme';
import { humanTimeZoneName } from '@utils/formatters/date';
import { getApiConfig } from '@utils/getApiConfig';

const apiConfig = getApiConfig();

const CreateProfile: React.FC<
  RegisterStackScreenProps<'create-profile'>
> = () => {
  const navigation = useNavigation();
  const [showMessage, setShowMessage] = useState(true);
  const [bodyWidth, setBodyWidth] = useState(0);
  const [bodyHeight, setBodyHeight] = useState(0);
  const [showCaptchaError, setShowCaptchaError] = useState(false);
  const [captchaToken, setCaptchaToken] = useState('');
  const { t } = useTranslation();
  const {
    setFieldValue,
    errors,
    setErrors,
    values,
    touched,
    isValid,
    setTouched,
    submitForm,
  } = useFormikContext<RegisterFormValues>();

  const [loading, setLoading] = useState<boolean>(false);

  const { userInfo } = useGoogleAuth();
  const setTimeZone = (value: string) => {
    setFieldValue('time_zone', value);
  };

  useEffect(() => {
    setFieldValue('step', 2);
    setTimeout(() => setShowMessage(false), 5000);
  }, []);

  useEffect(() => {
    const handleBackButton = () => {
      setFieldValue('step', 1);
    };

    window.addEventListener('popstate', handleBackButton);

    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, []);

  const verifyPhoneNumber = async () => {
    setLoading(true);
    const verifyPhoneNumberURL = `/api/v2/phone_number_available.json`;
    const userPayload = {
      phone_number: values.phone,
    };

    const authEndpoint = `${apiConfig.apiUrl}${verifyPhoneNumberURL}`;

    await fetch(authEndpoint, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(userPayload),
    })
      .then((response) => response.json())
      .then(async (response) => {
        setLoading(false);
        if (response?.error) {
          setErrors({
            phone:
              response.error === 'Phone number has already been taken'
                ? 'This phone number already exists. Please use other phone number or login.'
                : response.error,
          });
        } else {
          if (response.success) {
            if (process.env.PHONE_VERIFICATION_ENABLED === 'true') {
              navigation.navigate('register-with-phone-verify-code', {
                isRegiserWithPhoneNumber: false,
              });
            } else {
              const teamInvitationCode = window.sessionStorage.getItem(
                'account_invitations'
              );
              if (teamInvitationCode) {
                window.sessionStorage.setItem(
                  'do_not_show_invite_message',
                  '1'
                );

                submitForm();
              } else if (process.env.G_CAPTCHA_ENABLED != 'false') {
                if (captchaToken) {
                  setShowCaptchaError(false);
                  navigation.navigate('create-team');
                } else {
                  setShowCaptchaError(true);
                }
              } else {
                navigation.navigate('create-team');
              }
            }
          } else {
            Alert.alert('Phone number available method error.');
          }
        }
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  const handleOnCaptchaSuccess = (token: string) => {
    if (token) {
      setShowCaptchaError(false);
      setCaptchaToken(token);
    } else {
      setCaptchaToken('');
    }
  };

  const disabled =
    (touched.first_name && !!errors.first_name) ||
    (!!errors.first_name && !isValid) ||
    (touched.last_name && !!errors.last_name) ||
    (!!errors.last_name && !isValid) ||
    (touched.phone && !!errors.phone) ||
    (!!errors.phone && !isValid);

  const offsetPopButton: Partial<any> = {
    position: 'left',
    offset: [0, 6],
  };

  return (
    <Box
      flex={1}
      backgroundColor='grey01'
      alignItems='center'
      justifyContent='center'>
      <ShadowBox
        variant='base'
        backgroundColor='white'
        borderRadius='m'
        style={{
          width: 492,
        }}
        onLayout={(e) => {
          setBodyWidth(e.nativeEvent.layout.width);
          setBodyHeight(e.nativeEvent.layout.height);
        }}>
        <Box
          alignItems='center'
          borderRadius='m'
          px='xl'
          mx='xxs'
          py='l'
          my='xs'
          backgroundColor='white'>
          <Icon name='Logo' width={142} height={43} marginBottom='xl' />
          <Text variant='heading2' marginVertical='xxs'>
            {t('models:onBoarding.createYourProfile')}
          </Text>
          <Text variant='webBodySecondary' marginBottom='l'>
            {t('models:onBoarding.tellUsAboutYourself')}
          </Text>
          {loading && (
            <Box marginBottom='s'>
              <ActivityIndicator />
            </Box>
          )}
          <Field
            component={TextField}
            label='First Name'
            name='first_name'
            placeholder='Name'
            isRequired
            isLarge
            textInputProps={{
              editable: !userInfo?.given_name,
              color: userInfo?.given_name
                ? theme.colors.grey04
                : theme.colors.black,
              autoCapitalize: 'words',
              autoCorrect: false,
            }}
            accessibilityLabel='First Name'
          />
          <Field
            component={TextField}
            label='Last Name'
            name='last_name'
            placeholder='Surname'
            isRequired
            isLarge
            textInputProps={{
              editable: !userInfo?.family_name,
              color: userInfo?.family_name
                ? theme.colors.grey04
                : theme.colors.black,
              autoCapitalize: 'words',
              autoCorrect: false,
            }}
            accessibilityLabel='Last Name'
          />
          <Field
            component={PhoneField}
            label='Phone Number'
            name='phone'
            placeholder='(xxx)xxx-xxxx'
            isRequired
            isLarge
            accessibilityLabel='Phone Number'
            phoneIn
            value={values.phone}
          />

          <Box width={bodyWidth - 80} marginBottom='l'>
            <Text color='textPrimary' variant='labelSmall' marginBottom='xs'>
              Time Zone
            </Text>
            <PopupTimeZoneButtonWeb
              title={humanTimeZoneName(values.time_zone ?? '') ?? ''}
              setTimeZone={setTimeZone}
              boxProps={offsetPopButton}
              maxHeight={
                (Dimensions.get('window').height - bodyHeight) / 2 + 80
              }
            />
            <Box mt='m'>
              <GoogleReCaptcha
                showError={showCaptchaError}
                onCaptchaSuccess={handleOnCaptchaSuccess}
              />
            </Box>
          </Box>

          <Button
            disabled={loading || disabled}
            variant='primary'
            fullWidth
            isSmall
            onPress={() => {
              setTouched({
                ...touched,
                first_name: true,
                last_name: true,
                phone: true,
              });
              if (
                !(
                  (!!errors.first_name && !isValid) ||
                  (!!errors.last_name && !isValid) ||
                  (!!errors.phone && !isValid)
                )
              ) {
                verifyPhoneNumber();
              }
            }}
            accessibilityLabel={t('shared:createAccount')}>
            {t('shared:createAccount')}
          </Button>
        </Box>
      </ShadowBox>
      {showMessage && (
        <Box
          backgroundColor='black'
          borderRadius='xs'
          px='s'
          py='xs'
          style={{
            opacity: 0.8,
            position: 'absolute',
            bottom: 40,
          }}>
          <Text variant='webSmall' color='white'>
            {t('models:onBoarding.aConfirmationEmailHasBeenSent')}
          </Text>
        </Box>
      )}
    </Box>
  );
};

export default CreateProfile;
