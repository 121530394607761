import { createStackNavigator } from '@react-navigation/stack';
import React, { memo, useCallback } from 'react';

import ContactFormStack from '@components/Contacts/ContactFormStack.web';
import { EditContactGroup } from '@components/Contacts/EditContactGroup.web';
import { Box, ShadowBox } from '@components/Restyle';
import { useWebDrawer } from '@components/Web/Drawer/WebDrawerContext';
import { Task, User } from '@graphql/generated';
import { ContactGroupDetail } from '@navigation/contacts/contact-group-detail.web';

export type CreateContactStackParamsList = {
  'contact-group': {
    taskId?: Task['id'];
  };
  'contact-group-detail': {
    contactGroupId: string;
  };
};

type CreateContactStackProps = {
  contactGroupId?: string;
  userId?: User['id'];
};

const Stack = createStackNavigator<CreateContactStackParamsList>();

const CreateContactStack: React.FC<CreateContactStackProps> = memo(
  ({ contactGroupId }) => {
    const initialValues = {
      name: '',
      teamId: '',
      users: [],
    };
    const {
      editContactId,
      setEditContactId,
      setIdContactDetailOpen,
      setIsContactWebPanelOpen,
    } = useWebDrawer();

    const renderContent = useCallback(() => {
      if (editContactId) {
        return <EditContactGroup contactGroupId={editContactId} />;
      }

      if (contactGroupId && !editContactId) {
        return (
          <ContactGroupDetail
            contactGroupId={contactGroupId}
            onClose={() => {
              setEditContactId('');
              setIdContactDetailOpen('');
              setIsContactWebPanelOpen(false);
            }}
          />
        );
      }
      if (!contactGroupId && !editContactId) {
        return (
          <ContactFormStack
            screenName='CreateGroup'
            initialValues={initialValues}
          />
        );
      }
    }, [
      contactGroupId,
      editContactId,
      initialValues,
      setEditContactId,
      setIdContactDetailOpen,
      setIsContactWebPanelOpen,
    ]);

    return (
      <ShadowBox flex={1} p='m' ml='m'>
        <Stack.Navigator>
          <Stack.Screen name='contact-group' options={{ headerShown: false }}>
            {() => (
              <Box flex={1} backgroundColor='white'>
                {renderContent()}
              </Box>
            )}
          </Stack.Screen>
        </Stack.Navigator>
      </ShadowBox>
    );
  }
);

export default CreateContactStack;
