import * as Notifications from 'expo-notifications';
import { Platform } from 'react-native';


export const getDeviceId = async () => {
  try {
    if (Platform.OS === 'web') {
      let id = sessionStorage.getItem('uniqueDeviceId');
      if (!id) {
        id = crypto.randomUUID();
        sessionStorage.setItem('uniqueDeviceId', id);
      }
      return id;
    } else {
      const { data: notificationToken } =
        await Notifications.getExpoPushTokenAsync();
      return notificationToken;
    }
  } catch (error) {
    console.error('Error fetching device info:', error);
    return null;
  }
};
