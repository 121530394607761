import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';
import { useToast } from 'react-native-toast-notifications';

import Avatar, { AvatarProps } from '@components/Avatar/Avatar';
import MemberListSummary from '@components/MemberListSummary/MemberListSummary';
import { ConfirmModal } from '@components/Modals/ConfirmModal';
import { BoxHover } from '@components/Restyle/BoxHover.web';
import { Box, Text } from '@components/Restyle/index';
import Icon from '@components/shared/Icon/Icon';
import { MenuItem } from '@components/shared/Popup/PopupItem';
import { useWebDrawer } from '@components/Web/Drawer/WebDrawerContext';
import { PopupIcon } from '@components/Web/PopupIcon';
import { useDeleteGroupMutation, User } from '@graphql/generated';
import { useWebDrawerNavigator } from '@hooks/useWebDrawerNavigator';

interface Team {
  personal: boolean;
  name: string;
}

interface ListGroup {
  id: string;
  currentUserTeamRole: string;
  name: string;
  avatar: string;
  team: Team;
  users: User[];
}

interface ContactNameBoxProps {
  listGroups: ListGroup;
}

const ContactNameBox = ({ listGroups }: ContactNameBoxProps) => {
  const { selectedProject } = useWebDrawerNavigator();
  const [hoverState, setHoverState] = useState({
    isHovered: false,
    isHoveredTitle: false,
  });
  const { t } = useTranslation();

  const { id, name, team, avatar, users, currentUserTeamRole } = listGroups;

  const createHoverHandler = useCallback(
    (key: string, value: boolean) => () => {
      setHoverState((prev) => ({ ...prev, [key]: value }));
    },
    []
  );

  const handleMouseEnter = createHoverHandler('isHovered', true);
  const handleMouseLeave = createHoverHandler('isHovered', false);
  const handleTitleMouseEnter = createHoverHandler('isHoveredTitle', true);
  const handleTitleMouseLeave = createHoverHandler('isHoveredTitle', false);

  const userAvatars = (contacts: User[]): AvatarProps[] => {
    return (
      contacts?.map(({ id, name, avatar }) => ({
        id,
        label: name,
        avatar,
        size: 'memberList',
      })) || []
    );
  };

  const toast = useToast();

  const { setIsContactWebPanelOpen, setEditContactId, setIdContactDetailOpen } =
    useWebDrawer();
  const [showDeleteContactGroupModal, setShowDeleteContactGroupModal] =
    useState(false);

  const [deleteGroupMutation] = useDeleteGroupMutation({
    refetchQueries: ['listGroups'],
    onCompleted: () => {
      setIsContactWebPanelOpen(false);
      setIdContactDetailOpen('');
      setShowDeleteContactGroupModal(false);
      toast.show('Group deleted successfully', { duration: 2000 });
    },
  });

  const handleDeleteClick = () => {
    setShowDeleteContactGroupModal(true);
  };

  const menuList: MenuItem[] = [
    {
      name: 'Edit Group',
      iconName: 'Edit',
      onSelect: () => {
        setIdContactDetailOpen('');
        setEditContactId(id);
        setIsContactWebPanelOpen(true);
      },
    },
  ];

  return (
    <BoxHover
      isHovered={hoverState.isHovered}
      isSelected={selectedProject?.id === id}
      isProject={true}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}>
      <TouchableOpacity
        style={{ flex: 1 }}
        onPress={() => {
          setEditContactId('');
          setIdContactDetailOpen(listGroups.id);
          setIsContactWebPanelOpen(true);
        }}
        accessibilityLabel='contact-group-touch'>
        <Box flex={0.9}>
          <Box flexDirection='row' alignItems='center' height={48}>
            <Box
              alignItems='center'
              justifyContent='center'
              marginRight='xs'
              style={{ borderRadius: 5 }}>
              <Avatar
                id={id}
                avatar={avatar}
                size='memberList'
                skipCache={true}
                isMultiple={true}
                disableAvatarPress
              />
            </Box>
            <Box
              flex={1}
              flexDirection='row'
              alignItems='center'
              paddingRight='m'>
              <Text
                numberOfLines={1}
                variant='webBodySecondary'
                ellipsizeMode='tail'
                letterSpacing={0.2}
                textDecorationLine={
                  hoverState.isHoveredTitle ? 'underline' : undefined
                }
                onMouseEnter={handleTitleMouseEnter}
                onMouseLeave={handleTitleMouseLeave}>
                {name}
              </Text>
            </Box>
            <Box
              flex={1.5}
              flexDirection='row'
              alignItems='center'
              justifyContent='flex-start'
              padding='xxs'
              paddingRight='m'>
              <Icon
                marginRight='xs'
                name={team?.personal ? 'Office' : 'TT24'}
                variant='l'
                color={team?.personal ? 'white' : 'textPrimary'}
                backgroundColor={
                  team?.personal ? 'greenSecondary' : 'textPrimary'
                }
                padding={team?.personal ? 'xxs' : 'none'}
                width={team?.personal ? 16 : 22}
                height={team?.personal ? 16 : 22}
                borderRadius='xxs'
              />
              <Text
                numberOfLines={1}
                ellipsizeMode='tail'
                variant='bodySecondary'
                letterSpacing={0.2}>
                {team?.name}
              </Text>
            </Box>
            <Box
              flex={0.8}
              flexDirection='row'
              justifyContent='flex-start'
              paddingRight='m'>
              <Box
                alignItems='center'
                justifyContent='center'
                marginRight='xs'
                style={{ borderRadius: 5, padding: 6 }}
              />
            </Box>
            <Box
              flex={0.7}
              flexDirection='row'
              justifyContent='flex-end'
              paddingRight='m'>
              <MemberListSummary
                avatars={userAvatars(users)}
                onPress={() => {}}
                maxAvatars={3}
              />
            </Box>
          </Box>
        </Box>
      </TouchableOpacity>
      {currentUserTeamRole ? (
        <Box
          flex={0.2}
          flexDirection='row'
          alignItems='flex-end'
          justifyContent='flex-end'>
          <TouchableOpacity onPress={() => handleDeleteClick()}>
            <Icon
              name='DeleteBin'
              variant='l'
              color='black'
              accessibilityLabel='Delete'
            />
          </TouchableOpacity>

          <Box paddingHorizontal='m' flexDirection='row'>
            <PopupIcon
              accessibilityLabel='Options'
              name='MoreVertical'
              color='textPrimary'
              popupProps={{
                position: 'left',
                offset: [0, 1],
                menuList: menuList,
              }}
            />
          </Box>
        </Box>
      ) : (
        <Box flex={0.2} height={20} />
      )}

      {showDeleteContactGroupModal && (
        <ConfirmModal
          showModal={showDeleteContactGroupModal}
          onClose={() => setShowDeleteContactGroupModal(false)}
          onPress={() => {
            if (id) {
              deleteGroupMutation({ variables: { id: id } });
            }
          }}
          buttonText={t('models:deleteTask.title')}
          title={t('models:deleteTask.title')}
          message={t('models:contacts.deleteGroupMessage')}
        />
      )}
    </BoxHover>
  );
};

export default ContactNameBox;
