import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView } from 'react-native';
import { useToast } from 'react-native-toast-notifications';

import Avatar from '@components/Avatar/Avatar';
import { ConfirmModal } from '@components/Modals/ConfirmModal';
import { WebModal } from '@components/Modals/WebModal.web';
import { Box, Text } from '@components/Restyle';
import ActivityIndicatorLoading from '@components/shared/ActivityIndicatorLoading';
import Icon from '@components/shared/Icon/Icon';
import { MenuItem } from '@components/shared/Popup/PopupItem';
import { useWebDrawer } from '@components/Web/Drawer/WebDrawerContext';
import { PopupIcon } from '@components/Web/PopupIcon';
import { useDeleteGroupMutation, useGetGroupQuery } from '@graphql/generated';
import { AddContactGroupMembersStack } from '@navigation/addMembers/add-contact-group-member-stack.web';
import theme from '@themes/theme';

export const ContactGroupDetail = ({
  onClose,
  contactGroupId,
}: {
  onClose: () => void;
  contactGroupId: string;
}) => {
  const { t } = useTranslation();
  const [showAddMembersModal, setShowAddMembersModal] = useState(false);
  const [showDeleteContactGroupModal, setShowDeleteContactGroupModal] =
    useState(false);

  const { data, loading } = useGetGroupQuery({
    variables: { id: contactGroupId },
  });

  const { name, team, id, currentUserTeamRole, users } = data?.getGroup ?? {
    name: '',
  };
  const sortedUsers = users && [...users];

  const toast = useToast();

  const { setIsContactWebPanelOpen, setEditContactId, setIdContactDetailOpen } =
    useWebDrawer();

  const [deleteGroupMutation] = useDeleteGroupMutation({
    refetchQueries: ['listGroups'],
    onCompleted: () => {
      setEditContactId('');
      setIdContactDetailOpen('');
      setIsContactWebPanelOpen(false);
      setShowDeleteContactGroupModal(false);
      toast.show('Group deleted successfully', { duration: 2000 });
    },
  });

  const handleEditGroupClick = () => {
    setIdContactDetailOpen('');
    setIsContactWebPanelOpen(true);
    setEditContactId(contactGroupId);
  };

  const handleDeleteClick = () => {
    setShowDeleteContactGroupModal(true);
  };

  const menuList: MenuItem[] = [
    {
      name: 'Edit Group',
      iconName: 'Edit',
      onSelect: handleEditGroupClick,
    },
    {
      name: 'Delete',
      isRed: true,
      iconName: 'RedDelete',
      onSelect: handleDeleteClick,
    },
  ];

  if (loading) return <ActivityIndicatorLoading />;

  return (
    <>
      <Box pb='s' paddingHorizontal='s' backgroundColor='white'>
        <Box
          flexDirection='row'
          alignContent='flex-start'
          justifyContent='space-between'>
          <Box flex={1} flexDirection='row' justifyContent='space-between'>
            <Box>
              <Text
                accessibilityLabel='Contact Group Name'
                fontWeight='bold'
                variant='heading2'>
                {name}
              </Text>

              <Text
                accessibilityLabel='Contact Sub Title'
                marginTop='xxs'
                variant='labelLarge'>
                {t('models:contacts.contactGroup')}
              </Text>
            </Box>

            <Box flexDirection='row'>
              {currentUserTeamRole && (
                <PopupIcon
                  accessibilityLabel='Options'
                  name='MoreVertical'
                  color='textPrimary'
                  popupProps={{
                    position: 'left',
                    offset: [0, theme.spacing.m],
                    menuList: menuList,
                  }}
                />
              )}
              <Box paddingLeft='m' paddingTop='xxs'>
                <Icon accessibilityLabel='Close' name='X' onPress={onClose} />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box>
          <Text
            marginTop='l'
            accessibilityLabel='Team Title'
            variant='bodyTaskName'>
            {t('models:contacts.team')}
          </Text>

          <Box
            flexDirection='row'
            alignItems='center'
            justifyContent='flex-start'
            padding='xs'>
            <Icon
              marginRight='xs'
              name={team?.personal ? 'Office' : 'TT24'}
              variant='s'
              color={team?.personal ? 'white' : 'textPrimary'}
              backgroundColor={
                team?.personal ? 'greenSecondary' : 'textPrimary'
              }
              padding={team?.personal ? 'xxs' : 'none'}
              width={team?.personal ? 16 : 22}
              height={team?.personal ? 16 : 22}
              borderRadius='xxs'
            />
            <Box borderRadius='xxxs' padding='xxs' backgroundColor='grey02'>
              <Text
                numberOfLines={1}
                ellipsizeMode='tail'
                variant='metadata1'
                letterSpacing={0.2}>
                {team?.name}
              </Text>
            </Box>
          </Box>
        </Box>
        <Box>
          <Box flexDirection='row' mt='m' mb='s' justifyContent='space-between'>
            <Text accessibilityLabel='Contact Members' variant='bodyTaskName'>
              {t('models:contacts.members')}
            </Text>
            {currentUserTeamRole && (
              <Text
                accessibilityLabel='Add Members'
                onPress={() => {
                  setShowAddMembersModal(true);
                }}
                variant='bodyTaskName'
                numberOfLines={1}
                color='greenSecondary'>
                + Add Members
              </Text>
            )}
          </Box>

          <ScrollView
            showsVerticalScrollIndicator={false}
            style={{ height: '70vh' }}>
            <Box marginTop='m'>
              {users?.map((item) => {
                if (!item) return;
                const { name, avatar } = item;

                return (
                  <Box flexDirection='column' key={item.id}>
                    <Box flexDirection='row' alignItems='center' mb='xxs'>
                      <Avatar size='medium' label={name} avatar={avatar} />
                      <Box
                        flex={1}
                        flexDirection='row'
                        justifyContent='space-between'>
                        <Text
                          variant='labelSmall'
                          marginLeft='s'
                          textAlign='center'
                          numberOfLines={1}>
                          {name}
                        </Text>
                      </Box>
                    </Box>
                    <Box
                      height={1}
                      marginBottom='xxs'
                      backgroundColor='grey02'
                    />
                  </Box>
                );
              })}
            </Box>
          </ScrollView>
        </Box>
        {showDeleteContactGroupModal && (
          <ConfirmModal
            showModal={showDeleteContactGroupModal}
            onClose={() => setShowDeleteContactGroupModal(false)}
            onPress={() => {
              if (id) {
                deleteGroupMutation({ variables: { id: id } });
              }
            }}
            buttonText={t('models:deleteTask.title')}
            title={t('models:deleteTask.title')}
            message={t('models:contacts.deleteGroupMessage')}
          />
        )}

        <WebModal
          onClose={() => setShowAddMembersModal(false)}
          visible={showAddMembersModal}
          width={504}
          height={512}
          accessibilityLabel='Contact Group Members Modal'>
          <AddContactGroupMembersStack
            data={data?.getGroup}
            teamId={team?.id}
            members={sortedUsers ?? []}
            onClose={() => setShowAddMembersModal(false)}
          />
        </WebModal>
      </Box>
    </>
  );
};
