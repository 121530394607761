import React from 'react';

import { Box } from '@components/Restyle/index';
import { useWebDrawer } from '@components/Web/Drawer/WebDrawerContext';
import { User } from '@graphql/generated';

import ContactNameBox from './ContactNameBox.web';

interface Team {
  personal: boolean;
  name: string;
}

export interface ListGroup {
  id: string;
  name: string;
  avatar: string;
  team: Team;
  users: User[];
}

export interface ContactGroupCardProps {
  listGroups: ListGroup;
}

export const ContactGroupCard = ({ listGroups }: ContactGroupCardProps) => {
  useWebDrawer();
  return (
    <Box accessibilityLabel='ContactGroup'>
      <ContactNameBox listGroups={listGroups} />
    </Box>
  );
};
