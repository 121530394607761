import { useNavigation, useRoute } from '@react-navigation/native';
import {
  createStackNavigator,
  StackScreenProps,
} from '@react-navigation/stack';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, ShadowBox, Text } from '@components/Restyle';
import ActivityIndicatorLoading from '@components/shared/ActivityIndicatorLoading';
import { Task, useGetTaskQuery, User } from '@graphql/generated';
import { ContactTabs } from '@navigation/contacts/contact-tabs.web';
import { TaskDetailTabs } from '@navigation/tasks/task-detail-tabs.web';

type ContactPanelProps = {
  userId?: User['id'];
  isMaximize: boolean;
  onMaximize: () => void;
  onClose: () => void;
};

export type ContactPanelParamList = {
  contact: undefined;
  'contact-task-details'?: { taskId: Task['id'] };
};

export type ContactPanelStackScreenProps<
  T extends keyof ContactPanelParamList
> = StackScreenProps<ContactPanelParamList, T>;

const Stack = createStackNavigator<ContactPanelParamList>();

export const ContactPanel: React.FC<ContactPanelProps> = ({
  userId,
  isMaximize,
  onMaximize,
  onClose,
}) => {
  const navigation = useNavigation();
  const { t } = useTranslation('models');
  const { params: { taskId } = { taskId: undefined } } =
    useRoute<ContactPanelStackScreenProps<'contact-task-details'>['route']>() ||
    {};

  const { data: { getTask: task } = {} as { getTask: Task }, loading } =
    useGetTaskQuery({
      variables: {
        id: taskId ?? '',
      },
      skip: !taskId,
      fetchPolicy: 'cache-and-network',
    });

  const onTaskClose = () => {
    navigation.navigate('contacts', {
      screen: 'contact',
      userId,
      params: {
        screen: 'contact-tasks',
      },
    });
  };

  if (!userId) {
    return (
      <Box flex={1} paddingTop='m' alignItems='center'>
        <Text>{t('profile.userNotFound')}</Text>
      </Box>
    );
  }

  return (
    <ShadowBox key={userId} flex={1} p='l'>
      <Stack.Navigator>
        <Stack.Screen name='contact' options={{ headerShown: false }}>
          {() => (
            <ContactTabs
              userId={userId}
              onClose={onClose}
              isMaximize={isMaximize}
              onMaximize={onMaximize}
            />
          )}
        </Stack.Screen>
        <Stack.Screen
          name='contact-task-details'
          getId={() => taskId}
          options={{ headerShown: false }}>
          {() => {
            if (loading) return <ActivityIndicatorLoading />;

            return (
              task && (
                <TaskDetailTabs
                  task={task}
                  onClose={onTaskClose}
                  isMaximize={isMaximize}
                  onMaximize={onMaximize}
                />
              )
            );
          }}
        </Stack.Screen>
      </Stack.Navigator>
    </ShadowBox>
  );
};
