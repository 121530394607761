import React from 'react';
import { TouchableOpacity, Animated, ViewStyle, StyleProp } from 'react-native';

type ToggleSwitchProps = {
  value: boolean;
  style?: StyleProp<ViewStyle>;
  onValueChange: (value: boolean) => void;
  testID?: string;
};
import { Box } from '@components/Restyle';
import theme from '@themes/theme';

const ToggleSwitch: React.FC<ToggleSwitchProps> = (props) => {
  const enabled = props.value;
  const circleWidth = 24;
  const switchWidth = 56;
  const AnimatedBox = Animated.createAnimatedComponent(Box);
  return (
    <TouchableOpacity
      activeOpacity={1}
      onPress={() => {
        props.onValueChange(!enabled);
      }}
      testID={props.testID}
      style={props.style && []}>
      <AnimatedBox
        width={switchWidth}
        borderRadius='m'
        alignItems='center'
        flexDirection='row'
        style={{
          backgroundColor: enabled
            ? theme.colors.greenSecondary
            : theme.colors.grey03,
        }}>
        <AnimatedBox
          width={circleWidth}
          height={circleWidth}
          borderRadius='s'
          backgroundColor='background'
          marginRight='xxs'
          marginVertical='xxs'
          style={{
            marginLeft: enabled
              ? theme.spacing.xxs + circleWidth
              : theme.spacing.xxs,
          }}
        />
      </AnimatedBox>
    </TouchableOpacity>
  );
};
export default ToggleSwitch;
