import React from 'react';

import { Box, Text } from '@components/Restyle'; // Replace with your actual UI library

interface CustomToastProps {
  message: string;
}

const CustomToast: React.FC<CustomToastProps> = ({ message }) => {
  return (
    <Box
      style={{
        padding: 10,
        backgroundColor: '#303742',
        borderRadius: 5,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        gap: 8,
        position: 'relative',
      }}>
      <Text variant='labelSmall' color='white'>
        {message}
      </Text>
    </Box>
  );
};

export default CustomToast;
