import { useNavigation, useRoute } from '@react-navigation/native';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, FlatList, Platform } from 'react-native';

import EmptyStateNoFiles from '@components/EmptyState/EmptyStateNoFiles';
import FileCard from '@components/Files/FileCard';
import { NotPaidConfirmModal } from '@components/Modals/NotPaidConfirmModal';
import { Box, Text } from '@components/Restyle';
import ActivityIndicatorLoading from '@components/shared/ActivityIndicatorLoading';
import { Document, useGetTaskQuery } from '@graphql/generated';
import { useListTaskDocumentsFromQuery } from '@hooks/useListTaskDocumentsFromQuery';
import useMe from '@hooks/useMe';
import { usePreviewDocuments } from '@hooks/usePreviewDocuments';
import { useProjectMembership } from '@hooks/useProjectMembership';
import { TasksStackScreenProps } from '@navigation/tasks/tasks-stack';
import { ImagesPreview } from '@screens/Chats/ImagesPreview.web';
import { FilterType } from '@screens/Projects/ProjectFiles';
import { TasksFilesProps } from '@screens/Tasks/TaskFiles';
import { openInNewTab } from '@utils/fileUtils';

const AllTaskFiles: React.FC<TasksFilesProps> = ({
  filterBy,
  sortBy,
  isSelectMode,
  handelSelectedItem,
  onSelectedModeChange,
  selectedList,
}) => {
  const navigation = useNavigation();
  const { setPreviewDocuments, refreshList, setRefreshList } =
    usePreviewDocuments();
  const [isImageCarouselVisible, setImageCarouselVisible] = useState(false);

  const { t } = useTranslation();
  const { isPaid, setPaid, me } = useMe();
  const [isPaidUser, setPaidUser] = useState<boolean>(!!isPaid);
  const [showNotPaidConfirmModal, setShowNotPaidConfirmModal] =
    useState<boolean>(false);

  const showNotPaidModal = (file: Document) => {
    const createdAt = dayjs(file.createdAt);
    const expired = createdAt?.diff(new Date(), 'day');
    if (!isPaidUser && expired < -1000) {
      if (Platform.OS === 'web') {
        setShowNotPaidConfirmModal(true);
      } else {
        Alert.alert(
          'Task ' + t('shared:attachmentsExpired'),
          t('shared:attachmentsExpiredMessage'),
          [
            { text: 'Cancel' },
            {
              text: t('shared:upgrade'),
              style: 'destructive',
              onPress: () => {
                setPaid(true);
                setPaidUser(true);
              },
            },
          ]
        );
      }
      return true;
    }
    return false;
  };

  const route = useRoute<TasksStackScreenProps<'task-members'>['route']>();

  const {
    params: { taskId = '' },
  } = route || {};

  const { documents, loading, refetch, pageInfo, fetchFromCursor } =
    useListTaskDocumentsFromQuery({
      taskIds: [taskId],
      ownerIds:
        filterBy === FilterType.MyUploads && me?.id ? [me?.id] : undefined,
      sortBy,
      includeFiles: true,
    });

  const { data: taskData, loading: taskLoading } = useGetTaskQuery({
    variables: {
      id: taskId,
    },
    skip: !taskId,
  });

  const { getTask: task } = taskData || {};
  const { isEditorOrHigher } = useProjectMembership(task?.project);
  const isTeamMember = task?.project?.currentUserTeamRole === 'MEMBER';

  useEffect(() => {
    if (refreshList) {
      refetch().then((_result) => {
        setRefreshList(false);
      });
    }
  }, [refreshList]);

  if (loading || taskLoading) {
    return <ActivityIndicatorLoading />;
  }

  if (!task) {
    return (
      <Box flex={1} paddingTop='m' alignItems='center'>
        <Text>Task not found</Text>
      </Box>
    );
  }

  const renderItem = ({ item }: { item: Document }) => {
    return (
      <Box marginHorizontal='m'>
        <FileCard
          key={item.id}
          document={item}
          selectedList={selectedList}
          onMorePress={
            (Platform.OS !== 'web' &&
              (() => {
                setPreviewDocuments([{ ...item }]);
                navigation.navigate('task-file-modal', {
                  taskId,
                  projectId: task.projectId,
                  isEditorOrHigher: isEditorOrHigher,
                  isTeamMember: isTeamMember,
                  item: item,
                });
              })) ||
            undefined
          }
          onPress={() => {
            if (showNotPaidModal(item)) return;
            if (isSelectMode) {
              handelSelectedItem(item);
            } else {
              setPreviewDocuments([{ ...item }]);
              if (Platform.OS === 'web') {
                if (item.contentType?.startsWith('image/')) {
                  return setImageCarouselVisible(true);
                } else {
                  return openInNewTab(item);
                }
              } else if (item.contentType?.startsWith('image/')) {
                navigation.navigate('images-preview', { deleteCall: 'task' });
              } else {
                navigation.navigate('file-preview', {
                  item,
                  location: 'task',
                });
              }
            }
          }}
          onLongPress={(image) => {
            onSelectedModeChange(true);
            handelSelectedItem(image);
          }}
          showTags={false}
          isSelectMode={isSelectMode}
          onLongPress={() => {
            onSelectedModeChange(true);
            handelSelectedItem(item);
          }}
        />
      </Box>
    );
  };

  const renderEmptyUI = () => {
    return !loading && <EmptyStateNoFiles />;
  };

  const renderFooterUI = (isLoadingMore: boolean) => {
    return isLoadingMore ? (
      <>
        <ActivityIndicatorLoading />
        <Box marginBottom='listFooter' />
      </>
    ) : (
      <Box marginBottom='listFooter' />
    );
  };

  return (
    <Box flex={1}>
      {loading && documents.length == 0 ? (
        <>
          <ActivityIndicatorLoading />
          <Box marginBottom='listFooter' />
        </>
      ) : (
        <FlatList
          data={documents}
          renderItem={renderItem}
          initialNumToRender={20}
          ItemSeparatorComponent={() => <Box marginBottom='l' />}
          ListHeaderComponent={() => <Box marginTop='l' />}
          ListFooterComponent={() =>
            renderFooterUI(pageInfo?.hasNextPage || false)
          }
          ListEmptyComponent={() => renderEmptyUI()}
          contentContainerStyle={{ flexGrow: 1 }}
          showsHorizontalScrollIndicator={false}
          showsVerticalScrollIndicator={false}
          onEndReachedThreshold={0.1}
          onEndReached={() => {
            fetchFromCursor();
          }}
        />
      )}
      {showNotPaidConfirmModal && (
        <NotPaidConfirmModal
          showModal={showNotPaidConfirmModal}
          onClose={() => setShowNotPaidConfirmModal(false)}
          onPress={() => {
            setShowNotPaidConfirmModal(false);
            setPaid(true);
            setPaidUser(true);
          }}
          title='Task'
        />
      )}
      {Platform.OS === 'web' && isImageCarouselVisible && (
        <ImagesPreview onDismiss={() => setImageCarouselVisible(false)} />
      )}
    </Box>
  );
};

export default AllTaskFiles;
