import { NavigationProp, useNavigation } from '@react-navigation/native';
import { Field, useFormikContext } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

import { AddMembersSection } from '@components/Common/AddMembersSection.web';
import {
  ContactFormStackParamList,
  FormValues,
} from '@components/Contacts/ContactFormStack.web';
import { CancelConfirmTaskAndProjectModal } from '@components/Modals/CancelConfirmTaskAndProjectModal';
import { WebModal } from '@components/Modals/WebModal.web';
import { Box, Text } from '@components/Restyle';
import Button from '@components/shared/Button/Button';
import Icon from '@components/shared/Icon/Icon';
import TextField from '@components/shared/TextField/TextField';
import { useWebDrawer } from '@components/Web/Drawer/WebDrawerContext';
import { useListTeamsFromQuery } from '@hooks/useListTeamsFromQuery';
import useSelectedMembers from '@hooks/useSelectedMembers';
import { AddContactGroupMembersStack } from '@navigation/addMembers/add-contact-group-member-stack.web';
import { getTaskRoleEnumValue } from '@utils/tasks';

interface ContactGroupFormProps {
  screenName?: string;
  currentUserTeamRole?: string;
}

export const ContactGroupForm: React.FC<ContactGroupFormProps> = ({
  screenName,
  currentUserTeamRole,
}) => {
  const { t } = useTranslation();

  const {
    values: { teamId, users },
    touched,
    setTouched,
    submitForm,
    setFieldTouched,
    setFieldValue,
    isValid,
    isSubmitting,
  } = useFormikContext<FormValues>();

  const { setIsContactWebPanelOpen } = useWebDrawer();

  const navigator = useNavigation<NavigationProp<ContactFormStackParamList>>();

  const { teams } = useListTeamsFromQuery();
  const [touchedTeam, setTouchedTeam] = useState(false);
  const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);

  const [showAddMembersModal, setShowAddMembersModal] = useState(false);

  const selectedTeam = teams.find((item) => item.id === teamId);
  const { name: selectedTeamName } = selectedTeam || {};
  const teamIcon = selectedTeamName ? (
    <Icon name='Logo' variant='l' color='white' disabled={false} />
  ) : undefined;

  const sortedUsers = users && [...users];

  const { selectedMembers, setSelectedMembers } = useSelectedMembers();

  useEffect(() => {
    selectedMembers && setFieldValue('users', selectedMembers);
  }, [selectedMembers]);

  useEffect(() => {
    setSelectedMembers(sortedUsers);
    return () => setSelectedMembers([]);
  }, []);

  const onPressCancel = () => {
    setIsCancelModalVisible(false);
    setIsContactWebPanelOpen(false);
  };

  const updateMember = (userId: string, role: string) => {
    const members = sortedUsers?.map((u) => {
      if (u.id !== userId) return u;
      else {
        return { ...u, role: getTaskRoleEnumValue(role) };
      }
    });
    setSelectedMembers(members);
    setFieldValue('users', members);
    setTouched({ ...touched });
  };

  const createButton = () => {
    return (
      <Button
        marginTop='none'
        disabled={
          !isValid || isSubmitting || Object.entries(touched).length < 1
        }
        variant='primary'
        onPress={submitForm}
        float={undefined}
        accessibilityLabel={t('models:tasks.create.title')}>
        {screenName === 'CreateGroup'
          ? t('models:contacts.createContactGroup')
          : t('models:contacts.save')}
      </Button>
    );
  };

  // Inside your component
  const removeMember = useCallback(
    (member: { id: string }) => {
      const members = users?.filter((u) => u.id !== member.id);
      setFieldTouched('users');
      setFieldValue('users', [...members]);
    },
    [users, setFieldTouched, setFieldValue]
  );

  const handleTeamSelectOnClick = () => {
    navigator.navigate('team-select');
    setTimeout(() => {
      setTouchedTeam(true);
    }, 500);
  };

  const title =
    screenName === 'CreateGroup'
      ? t('models:contacts.createContactGroup')
      : t('models:contacts.editContactGroup');
  const buttonTextOne =
    screenName === 'CreateGroup'
      ? t('models:tasks.cancel.btnCreateText')
      : t('models:tasks.cancel.btnEditText');

  const buttonText =
    screenName === 'CreateGroup'
      ? t('models:tasks.cancel.removeTask')
      : t('models:tasks.cancel.btnCancelText');

  const buttonMsg =
    screenName === 'CreateGroup'
      ? t('models:tasks.cancel.message')
      : t('models:tasks.cancel.editMessage');

  const buttonTitle =
    screenName === 'CreateGroup'
      ? t('models:tasks.cancel.title')
      : t('models:tasks.cancel.cancelEditingTitle');

  return (
    <Box flex={1} paddingHorizontal='s' maxWidth={560} backgroundColor='white'>
      <KeyboardAwareScrollView
        showsVerticalScrollIndicator={false}
        style={{ flex: 1 }}>
        <Box
          flexDirection='row'
          alignContent='flex-start'
          justifyContent='space-between'>
          <Box flex={1} flexDirection='row' justifyContent='space-between'>
            <Box>
              <Text
                accessibilityLabel='Contact Group Name'
                fontWeight='bold'
                variant='heading2'>
                {title}
              </Text>
            </Box>

            <Box flexDirection='row'>
              <Box paddingTop='xxs'>
                {screenName === 'CreateGroup' && (
                  <Icon
                    accessibilityLabel='Close'
                    name='X'
                    onPress={() => {
                      setIsContactWebPanelOpen(false);
                    }}
                  />
                )}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box flex={1} marginTop='s' style={{ paddingBottom: 140 }}>
          <Field
            component={TextField}
            label='Name'
            accessibilityLabel='Name'
            name='name'
            isRequired
            placeholder={t('models:contacts.placeholders.name')}
            showCharsRemaining
            textInputProps={{ maxLength: 25 }}
          />

          <Box flexDirection='column' marginTop='s'>
            <Box flex={1}>
              <Box
                flexDirection='row'
                alignItems='flex-end'
                justifyContent='center'>
                <Text marginBottom='xs' variant='bodyTaskName'>
                  {t('models:contacts.team')}
                </Text>
                <Box flex={1} />
                <Text marginBottom='xs' variant='labelRequired'>
                  {t('models:projects.create.required')}
                </Text>
              </Box>

              <Button
                variant='edit'
                fullWidth
                isSmall
                marginBottom='s'
                onPress={handleTeamSelectOnClick}
                prefix={
                  <Box
                    width={24}
                    height={24}
                    alignItems='center'
                    justifyContent='center'
                    backgroundColor={
                      selectedTeam?.personal ? 'greenSecondary' : undefined
                    }
                    borderRadius='xxs'
                    marginRight='m'>
                    {selectedTeam?.personal ? (
                      <Icon
                        name='Office'
                        variant='s'
                        color='white'
                        disabled={false}
                      />
                    ) : (
                      teamIcon
                    )}
                  </Box>
                }
                accessibilityLabel='Assign Team'>
                <Text
                  variant='bodyTaskName'
                  color={selectedTeamName ? 'textPrimary' : 'grey04'}>
                  {selectedTeamName || 'Assign Team'}
                </Text>
              </Button>

              {touchedTeam && !selectedTeamName && (
                <Box flexDirection='row' alignItems='center' marginBottom='s'>
                  <Icon
                    name='AlertTriangle'
                    color='alertRed'
                    variant='s'
                    marginRight='xxs'
                  />
                  <Text variant='error'>
                    {t('models:projects.create.teams.teamIsRequired')}
                  </Text>
                </Box>
              )}
            </Box>

            <AddMembersSection
              currentUserTeamRole={currentUserTeamRole}
              isCreateContactGroup
              members={sortedUsers}
              updateMember={(member, role) => updateMember(member.id, role)}
              removeMember={removeMember}
              addMembers={() => {
                setFieldTouched('users', true);
                setShowAddMembersModal(true);
              }}
            />
          </Box>
        </Box>
      </KeyboardAwareScrollView>

      <WebModal
        onClose={() => setShowAddMembersModal(false)}
        visible={showAddMembersModal}
        width={504}
        height={512}
        accessibilityLabel='Contact Group Members Modal'>
        <AddContactGroupMembersStack
          teamId={teamId}
          isCreateContactGroup
          members={sortedUsers}
          onClose={() => setShowAddMembersModal(false)}
        />
      </WebModal>

      <Box
        flex={1}
        position='absolute'
        bottom={0}
        right={0}
        left={0}
        flexDirection='row'
        justifyContent='space-between'>
        <Box flex={1} marginRight='s'>
          <Button
            backgroundColor='white'
            borderColor='black'
            variant='cancel'
            color='black'
            onPress={() => {
              setIsCancelModalVisible(true);
            }}
            accessibilityLabel={t('shared:cancel')}>
            {t('shared:cancel')}
          </Button>
        </Box>
        <Box flex={1}>{createButton()}</Box>
      </Box>

      {isCancelModalVisible && (
        <CancelConfirmTaskAndProjectModal
          showModal={isCancelModalVisible}
          onClose={() => setIsCancelModalVisible(false)}
          onPress={onPressCancel}
          buttonTextOne={buttonTextOne}
          buttonText={buttonText}
          title={buttonTitle}
          message={buttonMsg}
        />
      )}
    </Box>
  );
};
