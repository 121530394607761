import ExpoCachedImage from 'expo-cached-image';
import * as Device from 'expo-device';
import React from 'react';
import {
  Platform,
  Image,
  ImageResizeMode,
  StyleProp,
  ImageStyle,
} from 'react-native';

import { Box } from '@components/Restyle';
import { cdnBaseUrl } from '@screens/Chats/ImagesPreviewNotLogin.web';
import theme from '@themes/theme';

type CachedImageNoLoginPropsType = {
  image: string;
  width?: number;
  screenWidth?: number;
  height?: number;
  marginRight?: number;
  isRemoveable?: boolean;
  croppingStrategy?: 'c-at_max_enlarge';
  resizeMode?: ImageResizeMode;
  borderRadius?: number;
  skipCache?: boolean;
  style?: Omit<StyleProp<ImageStyle>, 'width' | 'height' | 'opacity'>;
  placeholderContentFlex?: 0 | 1;
  from?: string;
};

const CachedImageNoLogin: React.FC<CachedImageNoLoginPropsType> = ({
  image,
  width,
  screenWidth,
  height,
  isRemoveable,
  borderRadius,
  resizeMode,
  croppingStrategy,
  skipCache = true,
  style: styleProp,
  placeholderContentFlex = 1,
  from = '',
}) => {
  const CachedImageForPlatform =
    Platform.OS === 'web' || skipCache ? Image : ExpoCachedImage;

  const isLocal = false;

  const format = 'f-auto';

  // ImageKit device density options:
  // https://docs.imagekit.io/features/image-transformations/resize-crop-and-other-transformations#dpr-dpr
  const density = Device.isDevice ? 'dpr-2' : 'dpr-1';

  // ImageKit image quality options:
  // https://docs.imagekit.io/features/image-transformations/resize-crop-and-other-transformations#quality-q
  // Note that a density setting of 2 is recommended to have a lower quality.

  let quality;

  if (from === 'search') {
    quality = Device.isDevice ? 'q-40' : 'q-60';
  } else {
    quality = Device.isDevice ? 'q-60' : 'q-80';
  }

  const transformations = [
    '/tr:',
    format,
    density,
    quality,
    width && `w-${width}`,
    height && `h-${height}`,
    croppingStrategy,
  ].filter(Boolean);

  const style: StyleProp<ImageStyle> = [
    {
      ...styleProp,
      width: screenWidth ? screenWidth : width,
      height: height || '100%',
      opacity: isLocal && !isRemoveable ? 0.5 : 1.0,
      backgroundColor: Platform.OS === 'web' ? theme.colors.grey02 : 'white',
      borderRadius: borderRadius || undefined,
    },
  ];

  const renderImage = () => {
    const transformationList = transformations.join(',');
    const cdnUrl = `${cdnBaseUrl}${transformationList}/${image}`;
    const cacheKey = `${transformationList}/${image}`.replace(/\W+/gi, '_');

    return (
      <CachedImageForPlatform
        source={{
          uri: cdnUrl,
          expiresIn: undefined, // never expire
        }}
        cacheKey={cacheKey}
        style={style}
        resizeMode={resizeMode || 'cover'}
        placeholderContent={
          <Box
            backgroundColor='grey02'
            style={{
              flex: placeholderContentFlex,
              height,
              width,
              borderRadius: borderRadius || undefined,
            }}
          />
        }
      />
    );
  };

  return renderImage();
};

export default CachedImageNoLogin;
